/**
 * @generated SignedSource<<eb98a7e198b1d54f176439fb6ca0b385>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SubscriptionPlatform = "android" | "browser" | "ios" | "%future added value";
export type SubscriptionFooterRevalidateMutation$variables = {};
export type SubscriptionFooterRevalidateMutation$data = {
  readonly revalidateSubscription: {
    readonly subscriptions: ReadonlyArray<{
      readonly famembers: ReadonlyArray<{
        readonly id: string;
        readonly name: string | null;
        readonly picture: string | null;
        readonly xps: {
          readonly currentStreak: number;
          readonly today: number;
          readonly total: number;
        };
      }>;
      readonly family: boolean;
      readonly platform: SubscriptionPlatform;
      readonly storeId: string;
      readonly timeToExpire: number;
      readonly transactionId: string;
    }>;
  } | null;
};
export type SubscriptionFooterRevalidateMutation = {
  response: SubscriptionFooterRevalidateMutation$data;
  variables: SubscriptionFooterRevalidateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "Subscription",
  "kind": "LinkedField",
  "name": "subscriptions",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "transactionId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timeToExpire",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "platform",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "storeId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "family",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "famembers",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "picture",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Xps",
          "kind": "LinkedField",
          "name": "xps",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "total",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "today",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "currentStreak",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SubscriptionFooterRevalidateMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "revalidateSubscription",
        "plural": false,
        "selections": [
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "SubscriptionFooterRevalidateMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "revalidateSubscription",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e577f7174e90b60e611c9a5df800b9a4",
    "id": null,
    "metadata": {},
    "name": "SubscriptionFooterRevalidateMutation",
    "operationKind": "mutation",
    "text": "mutation SubscriptionFooterRevalidateMutation {\n  revalidateSubscription {\n    subscriptions {\n      transactionId\n      timeToExpire\n      platform\n      storeId\n      family\n      famembers {\n        id\n        name\n        picture\n        xps {\n          total\n          today\n          currentStreak\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "48779149eea99a6979ac5178734e26ac";

export default node;
