import { useViewerDecks, useViewerQuery } from "../../../../queries/viewerQuery";
import { PICKED_FEW_CARDS } from "../../../../components/fire/xpHooks";

export function useCanShowDeleteHint(index: number) {
  const { deck } = useViewerDecks();
  const { hintWasSeen, getFlag } = useViewerQuery();

  const abtest_delayedCardFeaturePreviews = getFlag("abtest_delayedCardFeaturePreviews")?.value === "on";
  if (abtest_delayedCardFeaturePreviews && !hintWasSeen(PICKED_FEW_CARDS)) {
    return false;
  }

  return deck && deck.stats.known >= 6 && index === 1;
}
