import React from "react";
import styled from "styled-components/macro";
import { CARD_TRANS, CardFlipWrap, CardFront, Gesture } from "root/main/learn/Card";
import TouchAppIcon from "@mui/icons-material/TouchApp";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { setLearnSwipesOpacity } from "root/main/learn/LearnArrows";
import { DelSign } from "root/main/learn/Signs";
import { useSeeHint } from "components/hint/Hint";
import { HintDeleteIn } from "root/main/learn/CardHints";
import { useCanShowDeleteHint } from "./useCanShowDeleteHint";

export const CARDDELHINT = "CardDelete";
const MOVE = 50;
const CardFrontIn = styled.div`
  width: 50%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  @keyframes swipe-down {
    0% {
      top: 0;
      opacity: 1;
    }
    100% {
      top: 60px;
      opacity: 0;
    }
  }

  .touch-icon {
    font-size: 2.8rem;
    color: ${(p) => p.theme.duo.color.textDelete};
  }

  .arrow-icon {
    font-size: 2rem;
    color: ${(p) => p.theme.duo.color.textDelete};
    position: relative;
    animation: 2s swipe-down infinite;
  }
`;

type Props = {
  index: number;
};
const CardHintDelete: React.FC<Props> = React.memo((p) => {
  const [startPos, setStartPos] = React.useState({ x: 0, y: 0 });
  const seeHint = useSeeHint();
  const cardRef: React.RefObject<HTMLDivElement> = React.useRef(null);
  const delRef: React.RefObject<HTMLDivElement> = React.useRef(null);
  const [canRender, setCanRender] = React.useState(false);

  const canShowDeleteHint = useCanShowDeleteHint(p.index);

  React.useEffect(() => {
    if (canShowDeleteHint) {
      setCanRender(true);
    }
  }, [canShowDeleteHint]);

  if (!canRender) {
    return null;
  }

  const getX = (e) => e.pageX || (e.touches?.[0] || e.changedTouches?.[0])?.pageX;
  const getY = (e) => e.pageY || (e.touches?.[0] || e.changedTouches?.[0])?.pageY;
  const mv = (e) => getX(e) - startPos.x;
  const mvY = (e) => getY(e) - startPos.y;
  const moving = startPos.x !== 0;

  const applyMove = (e) => {
    if (!delRef.current || !cardRef.current) return;
    cardRef.current!.style.left = `${mv(e) * 1.3}px`;
    cardRef.current!.style.top = `${mvY(e) * 1.3}px`;
    cardRef.current!.style.transform = `rotate(${mv(e) / -15}deg)`;
    const down = mvY(e) > 1 ? mvY(e) / MOVE / 1.4 : 0;
    delRef.current!.style.opacity = `${down - Math.abs(mv(e) / MOVE)}`;
  };

  const handleGesture = (gesture: Gesture) => {
    if (!delRef.current || !cardRef.current) return;
    if (gesture === "delete") {
      cardRef.current!.style.top = `130%`;
      delRef.current!.style.opacity = "1";

      setTimeout(() => {
        seeHint(CARDDELHINT);
        setCanRender(false);
      }, CARD_TRANS);
    }
  };
  const resetMove = (gestured?: Gesture) => {
    if (!delRef.current || !cardRef.current) return;
    if (gestured === undefined) {
      delRef.current!.style.removeProperty("opacity");
      cardRef.current!.style.removeProperty("left");
      cardRef.current!.style.removeProperty("top");
    }
    cardRef.current!.style.removeProperty("transform");
  };
  const handleStart = (e) => {
    setStartPos({ x: getX(e), y: getY(e) });
  };
  const handleMove = (e) => {
    if (startPos.x === 0) return;
    applyMove(e);
    setLearnSwipesOpacity(0);
  };
  const handleStop = () => {
    if (p.index !== 0 || startPos.x === 0) return;
    let gesture: Gesture | undefined = undefined;
    Number(delRef.current!.style.opacity) >= 1 && (gesture = "delete");
    resetMove(gesture);
    gesture && handleGesture(gesture);
    setStartPos({ x: 0, y: 0 });
  };

  return (
    <CardFlipWrap
      index={p.index}
      moving={moving}
      ref={cardRef}
      onTouchStart={handleStart}
      onMouseDown={handleStart}
      onTouchMove={handleMove}
      onMouseMove={handleMove}
      onTouchEnd={handleStop}
      onMouseUp={handleStop}
      onMouseLeave={handleStop}
    >
      <CardFront style={{ alignItems: "center" }}>
        <CardFrontIn>
          <HintDeleteIn></HintDeleteIn>
          <TouchAppIcon className="touch-icon"></TouchAppIcon>
          <ArrowDownwardIcon className="arrow-icon"></ArrowDownwardIcon>
        </CardFrontIn>
      </CardFront>

      <DelSign ref={delRef} />
    </CardFlipWrap>
  );
});

export default CardHintDelete;
