import { IconButton } from "@mui/material";
import React from "react";
import styled from "styled-components/macro";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";

const Wrap = styled.div`
  position: absolute;
  text-align: center;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: fit-content;
`;
const SIconButton = styled(IconButton)`
  background: ${({ color, theme }) => (color === "primary" ? theme.duo.color.primary : "white")} !important;
  text-shadow: 0 0 8px white;
  box-shadow: 0.5px 0.5px 6px #0004;
  color: ${({ theme }) => theme.duo.color.primary}cc;
  height: 44px;
  width: 44px;
  margin: 0 4px;
  position: relative;
  z-index: 2;
  svg {
    font-size: 1.7rem;
  }
`;
const TurtleImg = styled.img`
  width: 28px;
  height: 28px;
`;

type Props = {
  yt: YT.Player;
  playing: boolean;
  style?: React.CSSProperties;
};

const Controls: React.FC<Props> = ({ yt, playing, style }) => {
  return (
    <Wrap style={style}>
      <SIconButton onClick={() => (playing ? yt.pauseVideo() : yt.playVideo())}>
        {playing ? <PauseIcon /> : <PlayArrowIcon />}
      </SIconButton>
      <SIconButton
        color={yt.getPlaybackRate() === 1 ? "default" : "primary"}
        onClick={() => (yt.getPlaybackRate() === 1 ? yt.setPlaybackRate(0.75) : yt.setPlaybackRate(1))}
      >
        <TurtleImg src="https://cdn.duocards.com/svg/turtle.svg" />
      </SIconButton>
    </Wrap>
  );
};

export default Controls;
