import { useEffect } from "react";
import { useGetState, useSetState } from "components/ReduxProvider";

export const useVoiceOffTimer = () => {
  const voiceOff = useGetState("voiceOff");
  const setVoiceOff = useSetState("voiceOff");
  const voiceOffUntil = useGetState("voiceOffUntil");
  const setVoiceOffUntil = useSetState("voiceOffUntil");

  useEffect(() => {
    if (voiceOffUntil && Date.now() >= voiceOffUntil) {
      setVoiceOff(false);
      setVoiceOffUntil(undefined);
    }
  }, [voiceOffUntil, setVoiceOff, setVoiceOffUntil]);

  useEffect(() => {
    if (!voiceOff) {
      setVoiceOffUntil(undefined);
    }
  }, [voiceOff, setVoiceOffUntil]);
};
