import React from "react";
import styled from "styled-components/macro";
import { Paper, IconButton, Box, Divider } from "@mui/material";
import { devicePaddings, isAndroid, isIos } from "../../../../tools/device";
import Loader from "components/other/Loader";
import { TOO_LONG } from "../../../../queries/translateQuery";
import { useTranslation } from "react-i18next";
import { useViewerQuery } from "../../../../queries/viewerQuery";
import Speech from "../../../../components/speech/Speech";
import { TUTORIALDONE } from "../../../tutorial/steps/Verdict";
import { useWindowSize } from "hooks/useWindowSize";
import useClickAway from "hooks/useClickAway";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faArrowsLeftRightToLine, faPenToSquare } from "@fortawesome/pro-regular-svg-icons";
import { useCopyToClipboard } from "react-use";
import { faCirclePlus } from "@fortawesome/pro-solid-svg-icons";

export const SAVEWORDID = "saveWordId";
export const POPPEDWORDID = "poppedWordId";

const { top: deviceTop } = devicePaddings();

const SPaper = styled(Paper)`
  position: absolute;
  max-width: 90%;
  z-index: 1400; // over oxford popup
  border-color: #aaaaaa;
`;

const Cont = styled.div`
  padding: 12px 24px 7px;
  text-align: center;
`;
const Translate = styled.div`
  position: relative;
  width: fit-content;
  font-size: ${({ theme }) => theme.duo.fontSize.medium};
  line-height: 1.45em;
  margin: 2px auto 4px;
`;
const Buttons = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 8px;
  margin-right: 8px;
  gap: 3px;
`;
const SIconButton = styled(IconButton)`
  color: ${({ theme }) => theme.duo.color.lightBlue};
`;
const SSpeech = styled(Speech)`
  display: inline;
  height: auto;
  width: auto;
  button {
    padding: 8px;
    margin: 0;
  }
  svg {
    width: 18px;
    height: 17px;
  }
`;
const SDivider = styled(Divider)`
  margin-left: 16px;
  margin-right: 16px;
`;

type Props = {
  translate: string | null;
  text?: string;
  anchorPos: { top?: number; left?: number };
  onSave: VoidFunction;
  onEdit: VoidFunction;
  onClickAway: VoidFunction;
  lang: string | undefined;
  onSelectSentence?: () => void;
};
const Word: React.FC<Props> = ({ text, translate, anchorPos, onSave, onClickAway, onEdit, lang, onSelectSentence }) => {
  const { t } = useTranslation();
  const [paperRect, setPaperRect] = React.useState({ width: 100, height: 100 });
  const { hintWasSeen } = useViewerQuery();
  const copyToClipboard = useCopyToClipboard()[1];

  const ref: React.RefObject<HTMLDivElement> = React.useRef(null);

  useClickAway(ref, onClickAway);

  React.useEffect(() => {
    const rect = ref.current?.getBoundingClientRect();
    rect && setPaperRect({ width: rect.width, height: rect.height });
  }, [anchorPos, translate]);

  const { width: windowWidth } = useWindowSize();
  const leftPos = (anchorPos.left || 0) - paperRect.width / 2;
  const maxLeft = windowWidth - paperRect.width - 10;
  const leftRaw = leftPos < maxLeft ? leftPos : maxLeft;
  const left = leftRaw < 10 ? 10 : leftRaw;

  const topPos = (anchorPos.top || 0) - paperRect.height - (isIos() ? 55 : 0);
  const top = topPos < deviceTop + 5 ? deviceTop + 5 : topPos;

  if (!text) return null;

  return (
    <SPaper id={POPPEDWORDID} ref={ref} elevation={8} style={{ left, top }} onClick={(e) => e.stopPropagation()}>
      {text.length > TOO_LONG ? (
        <Cont>{t("Text you selected is too long")}</Cont>
      ) : (
        <>
          <Cont>
            <Translate>{translate !== null ? translate || "" : <Loader size={33} />}</Translate>
          </Cont>

          <SDivider />
          <Buttons>
            {hintWasSeen(TUTORIALDONE) && (
              <>
                <SSpeech text={text} lang={lang} />

                <SIconButton
                  color="primary"
                  disabled={!onSelectSentence}
                  onMouseUp={(e) => {
                    e.preventDefault(); // we need to prevent default to avoid the click event
                    onSelectSentence && onSelectSentence();
                  }}
                  onTouchEnd={(e) => {
                    e.preventDefault(); // we need to prevent default to avoid the click event
                    onSelectSentence && onSelectSentence();
                  }}
                >
                  <FontAwesomeIcon icon={faArrowsLeftRightToLine} style={{ width: "21px" }} />
                </SIconButton>

                {isAndroid() && (
                  <SIconButton color="primary" onClick={() => copyToClipboard(text)}>
                    <FontAwesomeIcon icon={faCopy} style={{ width: "15px" }} />
                  </SIconButton>
                )}

                <SIconButton color="primary" onClick={translate === null ? undefined : onEdit}>
                  <FontAwesomeIcon icon={faPenToSquare} style={{ width: "17px" }} />
                </SIconButton>
              </>
            )}

            <Box sx={{ flexGrow: 1, minWidth: "10px" }} />

            <IconButton
              color="primary"
              id={SAVEWORDID}
              disabled={translate === null}
              onClick={onSave}
              size="large"
              sx={{ padding: "6px" }}
            >
              <FontAwesomeIcon icon={faCirclePlus} style={{ width: "28px" }} />
            </IconButton>
          </Buttons>
        </>
      )}
    </SPaper>
  );
};

export default Word;
