import React from "react";
import { Trans } from "react-i18next";
import styled from "styled-components/macro";
import { useGetState, useSetState } from "components/ReduxProvider";
import { useSeeHint } from "components/hint/Hint";
import ChromeHint from "root/tutorial/setting/ChromeHint";
import DialogWindow from "components/other/DialogWindow";
import BySharing from "root/tutorial/setting/BySharing";
import QuestReward from "../QuestReward";
import { dispatchEvent, EVENT, snackbar } from "tools/events";
import { Button } from "@mui/material";
import { useEffectOnce } from "react-use";
import { SHARE_AND_CHROME_QUEST } from "sharedJs__generated/constants";
import StreakAchievement from "../../../../../components/fire/StreakAchievement";
import { useViewerQuery, useViewerXps } from "../../../../../queries/viewerQuery";
import { QuestCloseButton } from "../misc/QuestCloseButton";
import { QuestMessage } from "../misc/QuestMessage";

const Wrap = styled.div``;
const P = styled.div<{ crossed?: boolean }>`
  margin: 10px 0 13px;
  text-decoration: ${({ crossed }) => (crossed ? "line-through" : "none")};
`;

type Props = {};

const ShareAndChrome: React.FC<Props> = () => {
  const reduxQuests = useGetState("quests");
  const seeHint = useSeeHint();
  const { getFlag } = useViewerQuery();
  const { xps } = useViewerXps();
  const [open, setOpen] = React.useState(!!reduxQuests?.addBySharing);
  const setShowQuestsBackdrop = useSetState("showQuestsBackdrop");
  const ignoreShareAndChrome = useGetState("ignoreShareAndChrome");
  const setIgnoreShareAndChrome = useSetState("ignoreShareAndChrome");

  const allDone = reduxQuests?.addBySharing && reduxQuests?.addChrome;

  useEffectOnce(() => {
    !reduxQuests?.addBySharing && !ignoreShareAndChrome && setShowQuestsBackdrop(true);
    dispatchEvent(EVENT.updateMain);
  });

  return (
    <QuestMessage visible={!allDone}>
      <Wrap>
        <P crossed={allDone}>
          <Trans>Explore how to add cards from anywhere on your phone or desktop</Trans>
        </P>
        {!allDone && (
          <Button
            variant="contained"
            onClick={() => {
              setOpen(true);
            }}
          >
            <Trans>great, let's go</Trans>
          </Button>
        )}
        {!ignoreShareAndChrome && (
          <QuestCloseButton
            onClick={() => {
              setShowQuestsBackdrop(false);
              setIgnoreShareAndChrome(true);
            }}
          />
        )}

        {open && (!reduxQuests?.addBySharing || !reduxQuests?.addChrome) && (
          <DialogWindow open wide>
            {!reduxQuests?.addBySharing ? <BySharing /> : <ChromeHint />}
          </DialogWindow>
        )}
        <QuestReward
          rewardedXps={5}
          claim={allDone}
          onDone={() => {
            seeHint(SHARE_AND_CHROME_QUEST);
            // for first time users show streak achievement
            if (xps?.days.length === 1) {
              snackbar(<StreakAchievement />, { severity: "info", confetti: true });
              if (getFlag("myGoal")?.value !== "0" && xps?.currentStreak === 1) {
                dispatchEvent(EVENT.openGoalDialog);
              }
            }
            setTimeout(() => dispatchEvent(EVENT.updateMain));
          }}
        />
      </Wrap>
    </QuestMessage>
  );
};

export default ShareAndChrome;
