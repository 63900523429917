import { Carousel } from "react-responsive-carousel";
import React, { useState } from "react";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import styled from "styled-components/macro";
import { ReassuranceSlide } from "./ReassuranceSlide";
import { Trans } from "react-i18next";
import { Box, Button } from "@mui/material";
import { topPadding, bottomPadding } from "../../../tools/device";
import { BrandTitle } from "../../../components/other/BrandTitle";
import LoginHeader from "../LoginHeader";

const SCarousel = styled(Carousel)``;

const Indicator = styled.li`
  display: inline-block;
  margin: 0;
  padding: 8px;
  width: 48px;
  text-align: center;
`;
const IndicatorButton = styled.div<{ $active?: boolean }>`
  display: inline-block;
  border: 1px solid ${(p) => p.theme.palette.primary.main};
  border-radius: 8px;
  background: ${(p) => (p.$active ? p.theme.palette.primary.main : "#fff")};
  width: ${(p) => (p.$active ? 32 : 16)}px;
  height: 16px;
  transition: all 0.4s ease-in-out;
`;
const Page = styled(Box)`
  height: 100%;
  padding: ${topPadding}px 32px ${bottomPadding + 40}px;
  background: white;
  position: relative;
  overflow: auto;
`;
const Content = styled(Box)`
  height: 100%;
  width: 100%;
  max-width: 400px;
  display: flex;
  margin: auto;
  flex-direction: column;
  justify-content: space-between;
`;

type Props = {
  onNext: () => void;
};

export function ReassuranceScreen(props: Props) {
  const customRenderItem = (item, props) => <item.type {...item.props} {...props} />;
  const [autoPlay, setAutoPlay] = useState(true);

  return (
    <Page>
      <Content>
        <LoginHeader />
        <Box sx={{ textAlign: "center" }}>
          <BrandTitle>DuoCards</BrandTitle>
        </Box>
        <SCarousel
          showArrows={false}
          showStatus={false}
          autoPlay={autoPlay}
          infiniteLoop
          onSwipeMove={() => {
            setAutoPlay(false);
            return true;
          }}
          renderItem={customRenderItem}
          renderIndicator={(onClickHandler, isSelected, index, label) => {
            return (
              <Indicator
                onClick={isSelected ? undefined : onClickHandler}
                onKeyDown={isSelected ? undefined : onClickHandler}
                key={index}
                role="button"
                tabIndex={0}
                title={`${label} ${index + 1}`}
                aria-label={`${label} ${index + 1}`}
              >
                <IndicatorButton $active={isSelected} />
              </Indicator>
            );
          }}
        >
          <ReassuranceSlide
            key="1"
            url="https://imgs.duocards.com/rive/reassurance-slide-1.riv"
            legend={
              <Trans>
                Learn any language easily, just like our community of <strong>5 million students</strong>!
              </Trans>
            }
          />
          <ReassuranceSlide
            key="2"
            url="https://imgs.duocards.com/rive/reassurance-slide-2.riv"
            legend={
              <Trans>
                <strong>Add words, phrases and sentences</strong> from anywhere
              </Trans>
            }
          />
          <ReassuranceSlide
            key="3"
            url="https://imgs.duocards.com/rive/reassurance-slide-3.riv"
            legend={
              <Trans>
                Swipe and let the <strong>algorithm do the rest</strong>
              </Trans>
            }
          />
          <ReassuranceSlide
            key="4"
            url="https://imgs.duocards.com/rive/reassurance-slide-4.riv"
            legend={
              <Trans>
                Practise with <strong>Memo, our AI assistant</strong>
              </Trans>
            }
          />
        </SCarousel>
        <Box sx={{ textAlign: "center", paddingLeft: 3, paddingRight: 3 }}>
          <Button variant="contained" color="primary" size="large" fullWidth onClick={props.onNext}>
            <Trans>Get started</Trans>
          </Button>
        </Box>
      </Content>
    </Page>
  );
}
