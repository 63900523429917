import { graphql } from "babel-plugin-relay/macro";
import { useMutation } from "relay-hooks";
import { useViewerDecks } from "../viewerQuery";
import { cardKnowMutation, cardKnowMutation$data } from "./__generated__/cardKnowMutation.graphql";
import { useGetState } from "components/ReduxProvider";
import { RecordSourceSelectorProxy } from "relay-runtime";

const mutation = graphql`
  mutation cardKnowMutation(
    $id: ID!
    $know: Boolean!
    $loopFilter: LoopFilter
    $filter: String
    $noResponse: Boolean
    $isLast: Boolean
  ) {
    cardKnow(id: $id, know: $know, filter: $filter, noResponse: $noResponse, isLast: $isLast) {
      ...loopQuery_loop @arguments(loopFilter: $loopFilter)
    }
  }
`;

export const useCardKnow = () => {
  const { deck } = useViewerDecks();
  const [mutate, { loading }] = useMutation<cardKnowMutation>(mutation);
  const loopFilter = useGetState("loopFilter");

  const cardKnow = (variables: cardKnowMutation["variables"]) => {
    const updater = (store: RecordSourceSelectorProxy<cardKnowMutation$data>, isOptimistic: boolean = false) => {
      const { know } = variables;
      const deckRec = store.get(deck?.id || "");
      let loop = deckRec?.getLinkedRecords("loop", { loopFilter });
      if (!deckRec) return;

      // update card stats when I swipe right (but also when I swipe last card left, as such card is considered known for next 2 minutes)
      if ((know || loop?.length === 1) && Number(deck?.stats.unknown) > 0) {
        const stats = deckRec.getLinkedRecord("stats");
        stats?.setValue(Number(deck?.stats.known) + 1, "known");
        stats?.setValue(Number(deck?.stats.unknown) - 1, "unknown");
      }

      // always remove the card except when we get actual data from BE
      if (isOptimistic || variables.noResponse) {
        loop = loop?.filter((card) => card?.getDataID() !== variables.id);
        deckRec.setLinkedRecords(loop, "loop", { loopFilter });
      }
    };

    const filter = loopFilter?.includeKnown ? loopFilter.filterId : undefined;
    return mutate({
      variables: { ...variables, ...{ loopFilter }, filter },
      optimisticUpdater: (store: RecordSourceSelectorProxy<cardKnowMutation$data>) => {
        updater(store, true);
      },
      updater: (store: RecordSourceSelectorProxy<cardKnowMutation$data>) => {
        updater(store, false);
      }
    });
  };
  return { cardKnow, loading };
};
