import { useEnvironment } from "../graphql/environment";
import { throttle } from "lodash";
import { useCallback } from "react";
import { hashCode, silenceTts, canTts, usePickVoices } from "./tts/ttsTools";
import { useGetState, useSetState } from "../components/ReduxProvider";
import { fetchTts } from "./tts/fetchTts";
import { MULTILINGUAL_LANG_CODE } from "sharedJs__generated/constants";
import { getTheme } from "styled/theme";
import { Howl } from "howler";
import { sentryCapture } from "sentry/sentry";

export const TOO_LONG = 200;

type Args = {
  text: string;
  speakingRate?: number;
  voiceId?: string;
  hash?: string;
  onStarted?: VoidFunction;
  onEnded?: VoidFunction;
  useServerCache?: boolean;
};

export const useTtsQuery = (language?: string, additionalLangs?: string[]) => {
  const environment = useEnvironment();
  const lastVoice = useGetState("lastVoice");
  const setLastVoice = useSetState("lastVoice");
  const cardsData = useGetState("idbCardsData");

  const lang = additionalLangs ? MULTILINGUAL_LANG_CODE : language;

  const sortedVoices = usePickVoices(lang, lastVoice?.voiceId);
  const firstVoice = sortedVoices?.[0];

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const ttsQuery = useCallback(
    throttle(
      ({ text, speakingRate, voiceId: voiceIdParam, onEnded, onStarted, useServerCache = true }: Args) => {
        const voiceId = voiceIdParam ? voiceIdParam : firstVoice;
        const speakUp = (base64: string) => {
          voiceId && setLastVoice({ voiceId, text });
          silenceTts();

          setTimeout(() => {
            const src = "data:audio/wav;base64," + base64;

            try {
              const sound = new Howl({
                src: [src],
                format: ["wav"],
                html5: true,
                onplay: onStarted,
                onend: onEnded,
                onloaderror: onEnded,
                onplayerror: onEnded,
                onstop: onEnded
              });

              sound.play();
            } catch (e) {
              onEnded?.();
              sentryCapture("Error playing sound: ", { e });
            }

            const toHighlight = hashCode(text, voiceIdParam);
            const els = document.getElementsByClassName(toHighlight);
            for (let el of els as any) {
              el.style.color = getTheme().color.primary;
              setTimeout(() => el && el.style.removeProperty("color"), 1000);
            }
          }, 10); // 10ms to make sure previous audio is stopped properly (by silenceTts)
        };

        const cached =
          cardsData &&
          !speakingRate &&
          cardsData.find(
            (cd) => cd.text === text && cd.lang === lang && (voiceId === cd.ttsVoiceId || lastVoice?.text !== text)
          );

        if (cached) {
          cached.tts && speakUp(cached.tts);
        } else {
          fetchTts(environment, { lang: lang || "", text, speakingRate, voiceId, useCache: useServerCache })
            .then((data) => {
              if (!data?.speech) {
                onEnded?.();
                return;
              }
              speakUp(data.speech);
            })
            .catch((e) => {
              onEnded?.();
              throw e;
            });
        }
      },
      1000,
      { trailing: false }
    ),
    [firstVoice, cardsData]
  );

  return canTts(language, additionalLangs) && cardsData ? ttsQuery : null;
};
