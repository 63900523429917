import { Quest } from "../Quest";
import ShareAndChrome from "./ShareAndChrome";
import { useViewerQuery } from "../../../../../queries/viewerQuery";

export function ShareAndChromeQuest() {
  const { getFlag } = useViewerQuery();
  const forcedInitialQuests = getFlag("abtest_forcedInitialQuests")?.value === "on";

  return (
    <Quest questName="ShareAndChromeQuest" requiredExps={forcedInitialQuests ? 1 : 0}>
      <ShareAndChrome />
    </Quest>
  );
}
