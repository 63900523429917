import { Quest } from "./Quest";
import FewMoreCards from "./FewMoreCards";

export function FewMoreCardsQuest() {
  return (
    <Quest questName="FewMoreCardsQuest" requiredExps={0}>
      <FewMoreCards />
    </Quest>
  );
}
