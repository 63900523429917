import React, { ReactNode } from "react";
import { langs } from "../sharedJs__generated/langsList";
import styled from "styled-components";
import { useGetLangTranslates } from "hooks/useGetLangTranslates";
import { SingleLangTranslationMap } from "components/ReduxProvider";

const Flag = styled.span`
  display: inline-flex;
  margin: 0 1px 1px 0;
  align-items: center;
`;

const countryPng = (countryCode?: string) =>
  !countryCode ? null : (
    <img
      src={`https://flagcdn.com/20x15/${countryCode}.png`}
      srcSet={`https://flagcdn.com/40x30/${countryCode}.png 2x,
    https://flagcdn.com/60x45/${countryCode}.png 3x`}
      alt=""
      width="20"
      height="15"
    />
  );
const pngFromUrl = (url?: string) => (!url ? null : <img src={url} alt="" width="20" height="15" />);

export const flag = (lang?: string | null): ReactNode => {
  if (!lang || !langs[lang]) return null;
  return (
    <Flag>
      {langs[lang].flag || pngFromUrl(langs[lang].flagPng) || countryPng(langs[lang].country || lang) || "🏳️"}
    </Flag>
  );
};

export const flagLabel = (lang?: string | null) => {
  if (!lang) return;

  return (
    <>
      {flag(lang)} &nbsp;
      <LangLabel lang={lang} />
    </>
  );
};

export const LangLabel = ({ lang }: { lang?: string }) => {
  const { langTranslates } = useGetLangTranslates();
  if (!lang) return null;
  return <>{getLocalizedLangLabelOrDefault(lang, langTranslates)}</>;
};

export const LabelTextWithLangLabel = ({ label, lang }: { label?: string; lang?: string }) => {
  if (!label) return null;
  return (
    <>
      {label} (<LangLabel lang={lang} />)
    </>
  );
};

type LangOption = {
  value: string;
  label: string;
  icon: ReactNode;
};

const langOptions: LangOption[] = Object.keys(langs).map((value) => ({
  value,
  label: langs[value].label,
  icon: flag(value)
}));

export const getLocalizedLangLabelOrDefault = (lang?: string, langTranslates?: SingleLangTranslationMap): string =>
  langTranslates?.[String(lang)] || langs[String(lang)]?.label;

export const translateLangOptions = (langTranslates?: SingleLangTranslationMap): LangOption[] => {
  return langOptions.map((option) => {
    const translated = langTranslates?.[option.value];
    return {
      ...option,
      label: translated || option.label
    };
  });
};
