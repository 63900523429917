import React, { useState, useRef } from "react";
import styled from "styled-components/macro";
import { IconButton, Menu, MenuItem, Switch, ListItemIcon, ListItemText } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCommentPlus, faVolume, faBars } from "@fortawesome/pro-regular-svg-icons";
import { dispatchEvent, EVENT } from "tools/events";
import { useGetState, useSetState } from "components/ReduxProvider";
import { useViewerQuery } from "queries/viewerQuery";
import { Trans } from "react-i18next";

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  font-size: 12px;
  width: 16px !important;
  height: 16px;
  color: ${(p) => p.theme.duo.palette.greyDark};
`;

type Props = {
  className?: string;
};

const HamburgerMenu: React.FC<Props> = ({ className }) => {
  const { getFlag } = useViewerQuery();

  const anchorRef = useRef<HTMLButtonElement>(null);
  const [open, setOpen] = useState(false);

  const assistantAutoSpeechOff = useGetState("assistantAutoSpeechOff");
  const setAssistantAutoSpeechOff = useSetState("assistantAutoSpeechOff");

  const abtest_assistantAutoSpeechOn = getFlag("abtest_assistantAutoSpeech")?.value === "on";
  const autoSpeechEnabled = !assistantAutoSpeechOff && abtest_assistantAutoSpeechOn;

  const handleMenuOpen = () => {
    setOpen(true);
  };

  const handleMenuClose = () => {
    setOpen(false);
  };

  const handleNewChat = () => {
    dispatchEvent(EVENT.resetAssistant);
    handleMenuClose();
  };

  const handleToggleAutoSpeech = () => {
    setAssistantAutoSpeechOff(!assistantAutoSpeechOff);
    handleMenuClose();
  };

  return (
    <div className={className}>
      <IconButton
        ref={anchorRef}
        aria-label="hamburger menu"
        aria-controls={open ? "hamburger-menu" : undefined}
        aria-haspopup="true"
        onClick={handleMenuOpen}
        color="inherit"
      >
        <StyledFontAwesomeIcon icon={faBars} />
      </IconButton>
      <Menu
        id="hamburger-menu"
        anchorEl={anchorRef.current}
        open={open}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
      >
        <MenuItem onClick={handleNewChat}>
          <ListItemIcon>
            <StyledFontAwesomeIcon icon={faCommentPlus} />
          </ListItemIcon>
          <ListItemText>
            <Trans>New chat</Trans>
          </ListItemText>
        </MenuItem>
        {abtest_assistantAutoSpeechOn && (
          <MenuItem onClick={handleToggleAutoSpeech}>
            <ListItemIcon>
              <FontAwesomeIcon icon={faVolume} />
            </ListItemIcon>
            <ListItemText>
              <Trans>Autoplay</Trans>
            </ListItemText>
            <Switch checked={autoSpeechEnabled} size="small"></Switch>
          </MenuItem>
        )}
      </Menu>
    </div>
  );
};

export default HamburgerMenu;
