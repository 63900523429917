import { useViewerDecks, useViewerQuery } from "queries/viewerQuery";
import React from "react";
import { FIVEHINT, STARTID } from "root/main/main/Main";
import BreakLibrary from "root/tutorial/steps/BreakLibrary";
import TutorialContent, { HintPosition, TutorialFC } from "./TutorialContent";
import { BOTTOMNAVID } from "../../components/other/BottomNavigation";
import { useMatch } from "react-router";
import { useMatchMain } from "root/RootRouter";
import ChooseSet from "root/tutorial/steps/ChooseSet";
import FirstFewCards, { FIRSTFEWHINT } from "./steps/FirstFewCards";
import { SOURCE } from "root/library/library/libraryUrls";
import AhoyMemooth, { TUTORIALWELCOME } from "./steps/AhoyMemooth";
import BeforeStart, { TUTORIALBEFORESTART } from "./steps/BeforeStart";
import TryVideoOrArticle from "./steps/FirstVideoOrArticle";
import LongTouch from "./steps/LongTouch";
import { STARTLEARNING, StartLearning1, StartLearning2 } from "./steps/StartLearning";
import { FIRSTLOOPDONE } from "root/main/learn/Learn";
import { CARD, DECK, LEARN } from "root/main/MainRouter";
import PetMammoth, { PETMAMMOTH } from "./steps/PetMammoth";
import TapCard, { TAPTUTORIALDONE } from "./steps/TapCard";
import ThenSwipeAllRight from "./steps/ThenSwipeAllRight";
import { CARDSID } from "root/main/learn/Cards";
import Verdict, { SWIPINGDONE, TUTORIALDONE } from "./steps/Verdict";
import { VOICEHINT } from "components/other/HintTooltip";
import { useSeeHint } from "components/hint/Hint";
import Notifications, { NOTIFICATIONSDONE } from "root/profile/Notifications";
import SettingsDone from "./setting/SettingsDone";
import { LIKEITHINT } from "root/main/main/LikeIt";
import FirstSubscription, { FIRSTSUBSCRIPTION } from "./misc/FirstSubscription";
import { useEvent } from "react-use";
import { GENERATED_APP_LANG, LIBRARY, PROFILE, VERIFY } from "../RootRouter.config";
import FeaturesHint, { MOTIVATIONHINT } from "./setting/FeaturesHint";

export const FIRSTCARDSCOUNT = 3;

const Tutorial: React.FC<{}> = () => {
  const { hintWasSeen } = useViewerQuery();
  const { deck } = useViewerDecks();
  const inProfile = useMatch(PROFILE.url() + "/*");
  const inLibrarySection = useMatch(LIBRARY.root() + "/*");
  const inSource = useMatch(SOURCE.query());
  const inMain = useMatchMain({ end: true });
  const inLearn = useMatch(LEARN.url());
  const inDeck = useMatch(DECK.url());
  const inVerify = useMatch(VERIFY.url());
  const inGeneratedAppLang = useMatch(GENERATED_APP_LANG.url());
  const inCard = useMatch(CARD.url());
  const seeHint = useSeeHint();

  // finishing tutorial on ctrl+D
  const finishTutorial = (e) => {
    if (process.env.NODE_ENV !== "development") return;
    if ((e.metaKey || e.ctrlKey) && e.code === "KeyD" && !hintWasSeen(TUTORIALDONE)) {
      e.preventDefault();
      seeHint(TUTORIALDONE);
    }
  };
  useEvent("keydown", finishTutorial);

  React.useEffect(() => {
    if (!hintWasSeen(TUTORIALDONE) && (hintWasSeen(VOICEHINT) || hintWasSeen(LIKEITHINT))) {
      seeHint(TUTORIALDONE); // some historic users
    }
  }, [deck, hintWasSeen, seeHint]);

  if (
    hintWasSeen(TUTORIALDONE) ||
    hintWasSeen(VOICEHINT) ||
    inDeck ||
    inProfile ||
    inVerify ||
    inGeneratedAppLang ||
    inCard ||
    !deck
  ) {
    return null;
  }

  if (!hintWasSeen(FIRSTSUBSCRIPTION)) {
    return <FirstSubscription />;
  }

  const config: {
    condition: () => any; // booleanish
    Component: TutorialFC;
    position?: HintPosition;
    visibleId?: string;
    movingMammoth?: boolean;
  }[] = [
    {
      condition: () => inMain && !hintWasSeen(TUTORIALWELCOME),
      Component: AhoyMemooth
    },
    {
      condition: () => inMain && !hintWasSeen(TUTORIALBEFORESTART),
      Component: BeforeStart
    },
    {
      condition: () => inMain && !hintWasSeen(FIVEHINT),
      Component: BreakLibrary,
      position: "aboveNavigation",
      visibleId: BOTTOMNAVID
    },
    {
      condition: () => !hintWasSeen(FIVEHINT) && !hintWasSeen(FIRSTFEWHINT) && inLibrarySection && !inSource,
      Component: ChooseSet
    },
    {
      condition: () => !hintWasSeen(FIRSTFEWHINT) && inSource,
      Component: FirstFewCards
    },
    {
      condition: () => !hintWasSeen(FIVEHINT) && deck.stats.total === FIRSTCARDSCOUNT && inLibrarySection && !inSource,
      Component: TryVideoOrArticle
    },
    {
      condition: () => inSource && !hintWasSeen(FIVEHINT),
      Component: LongTouch
    },
    {
      condition: () => !hintWasSeen(PETMAMMOTH),
      Component: PetMammoth
    },
    {
      condition: () => inMain && !hintWasSeen(STARTLEARNING),
      Component: StartLearning1
    },
    {
      condition: () => inMain && deck.stats.unknown && !hintWasSeen(FIRSTLOOPDONE),
      Component: StartLearning2,
      position: "bottom",
      visibleId: STARTID
    },
    {
      condition: () => inLearn && !hintWasSeen(TAPTUTORIALDONE),
      Component: TapCard,
      position: "bottom"
    },
    {
      condition: () => inLearn && !hintWasSeen(FIRSTLOOPDONE),
      Component: ThenSwipeAllRight,
      visibleId: CARDSID,
      position: "bottom"
    },
    {
      condition: () => !hintWasSeen(SWIPINGDONE),
      Component: Verdict
    },
    {
      condition: () => !hintWasSeen(NOTIFICATIONSDONE),
      Component: (p) => <Notifications {...p} inTutorial style={{ minHeight: "calc(100% - 60px)" }} />,
      position: "full"
    },
    {
      condition: () => !hintWasSeen(MOTIVATIONHINT),
      Component: FeaturesHint,
      position: "full"
    },
    {
      condition: () => true,
      Component: SettingsDone,
      position: "full"
    }
  ];

  const index = config.findIndex((cnf) => cnf.condition());
  const c = config[index];
  const settingsStartIndex = config.findIndex((c) => c.Component === Verdict) + 1;
  const perc = `${(100 / settingsStartIndex) * index}%`;
  const showProgressbar = settingsStartIndex > index;

  if (!c) return null;

  return (
    <TutorialContent
      visibleId={c.visibleId}
      perc={perc}
      step={index}
      showProgressbar={showProgressbar}
      position={c.position}
      Component={c.Component}
    />
  );
};

export default Tutorial;
