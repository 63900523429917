import { InputAdornment, MenuItem, Select, TextField } from "@mui/material";
import React from "react";
import styled from "styled-components/macro";
import DialogFullWindow from "../../../components/other/DialogFullWindow";
import SearchIcon from "@mui/icons-material/Search";
import { useTranslation } from "react-i18next";
import { graphql } from "babel-plugin-relay/macro";
import { useDebounce } from "react-use";
import { useQuery } from "relay-hooks";
import { CardImgPickerQuery } from "./__generated__/CardImgPickerQuery.graphql";
import { CardValues } from "../../../components/ReduxProvider";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import Loader from "components/other/Loader";
import { flag } from "../../../tools/langs";
import { uniq } from "lodash";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { stripGens } from "../../../sharedJs__generated/gens";
import { useMutation } from "react-relay";
import FlatImg from "components/other/FlatImg";
import { SEARCH, useSearchParamTools } from "tools/searchParams";
import { snackbar } from "tools/events";
import { FRONT_ID } from "./CardForm";

export const CARDIMG_ID = "cardimg";

const flatQL = graphql`
  query CardImgPickerQuery($term: String!, $lang: String!) {
    flatIcons(term: $term, lang: $lang) {
      id
      url
    }
  }
`;
const flatClearQL = graphql`
  mutation CardImgPickerMutation($term: String!, $lang: String!) {
    flatIconsClear(term: $term, lang: $lang)
  }
`;

const Wrap = styled.div`
  display: flex;
  justify-content: center;
`;
const ImgWrap = styled.div`
  margin: -5px 0;
  display: flex;
  svg,
  img {
    width: 100px;
    height: 100px;
  }
`;
const STextField = styled(TextField)`
  width: 76%;
  margin: -20px auto 0;
`;
const SSelect = styled(Select)`
  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
`;
const Results = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 10px 5px;
  max-height: 100%;
  overflow: auto;
`;
const Img = styled.img`
  cursor: pointer;
  width: 110px;
  height: 110px;
  padding: 15px;
`;
const AddImg = styled.img<{ disabled: boolean }>`
  width: 80px !important;
  height: 80px !important;
  margin-top: 15px;
  opacity: ${({ disabled }) => (disabled ? 0.4 : 0.9)};
  filter: grayscale(${({ disabled }) => (disabled ? 100 : 0)}%) hue-rotate(75deg) saturate(1.1);
  padding: 12px;
`;
const NoImgWrap = styled.div`
  position: relative;
  cursor: pointer;
  padding: 15px;
  img {
    opacity: 0.4;
  }
`;
const NoImgIco = styled(NotInterestedIcon)`
  position: absolute;
  top: 0;
  left: 0;
  padding: 25px;
  color: #aaa;
  width: 100%;
  height: 100%;
`;
const NoResults = styled.div`
  margin: 50px 30px;
  text-align: center;
  color: orange;
  font-size: ${({ theme }) => theme.duo.fontSize.small};
  line-height: 1.4em;
`;
const SHighlightOffIcon = styled(HighlightOffIcon)`
  margin-right: 6px;
  font-size: 18px;
  opacity: 0.4;
`;

type Props = {
  onChange: (img: CardValues["svg"]) => void;
  values: CardValues;
  disabled: boolean;
  langs: string[];
};

const CardImgPicker: React.FC<Props> = ({ onChange, values, disabled, langs }) => {
  const [value, setValue] = React.useState("");
  const [term, setTerm] = React.useState("");
  const { navigateSearchParam, backSearchParam, getSearchParam } = useSearchParamTools();
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState(false);
  const { t } = useTranslation();
  const [lang, setLang] = React.useState(langs[1] === "en" ? "en" : langs[0]);
  const {
    data: imgs,
    isLoading,
    retry
  } = useQuery<CardImgPickerQuery>(flatQL, { term: term.substring(0, 100), lang }, { skip: !term || !open });
  const [flatClear] = useMutation(flatClearQL);
  const searchRef = React.useRef<HTMLInputElement>(null);

  useDebounce(
    () => {
      setTerm(value);
      setError(false);
    },
    700,
    [value]
  );

  React.useEffect(() => {
    // we use state for opening to open img from specific form (in case there are more forms on screen)
    //  - for closing we use search param so native-back button also closes it
    if (!getSearchParam(SEARCH.openImage)) setOpen(false);
  }, [getSearchParam]);

  React.useEffect(() => {
    setValue(stripGens(langs[1] === "en" ? values.front : values.back, lang));
    setLang(langs[1] === "en" ? "en" : langs[0]);
  }, [values]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (error) {
      flatClear({ variables: { term, lang }, onCompleted: () => retry() });
    }
  }, [error, flatClear, term, lang, retry]);

  return (
    <Wrap id={CARDIMG_ID}>
      <ImgWrap
        onClick={() => {
          if (values.svg || !disabled) {
            navigateSearchParam(SEARCH.openImage);
            setOpen(true);
          } else {
            snackbar(t("Fill the form first"), { severity: "error" });
            document.getElementById(FRONT_ID)?.focus?.();
          }
        }}
      >
        {values.svg ? (
          <FlatImg img={values.svg} />
        ) : (
          <AddImg disabled={disabled} src="https://cdn.duocards.com/svg/addimage.svg" alt="add image" />
        )}
      </ImgWrap>

      <DialogFullWindow open={open} onClose={() => backSearchParam(SEARCH.openImage)}>
        <STextField
          value={value}
          onChange={(e) => setValue(e.currentTarget.value)}
          inputRef={searchRef}
          inputProps={{ id: "cardimgsearchid" }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <SHighlightOffIcon
                  onClick={() => {
                    setValue("");
                    setTerm("");
                    setTimeout(() => searchRef.current && searchRef.current.focus(), 100);
                  }}
                />
                <SSelect
                  value={lang}
                  onChange={(e) => {
                    setLang(e.target.value as string);
                    const term =
                      langs[1] === e.target.value ? values.front : langs[0] === e.target.value ? values.back : "";
                    setValue(term);
                    setTerm(term);
                    setTimeout(() => searchRef.current && searchRef.current.select(), 100);
                  }}
                >
                  {uniq(["en", ...langs]).map((langValue) => (
                    <MenuItem key={langValue} value={langValue}>
                      {flag(langValue)}
                    </MenuItem>
                  ))}
                </SSelect>
              </InputAdornment>
            )
          }}
          label={t("Search")}
        />
        {isLoading ? (
          <Loader />
        ) : (
          <>
            {!imgs?.flatIcons.length && term && value === term && (
              <NoResults>{t(`No images for {*}`).replace("{*}", `"${term}"`)}</NoResults>
            )}
            <Results>
              {imgs?.flatIcons?.map((img) => (
                <Img
                  onClick={() => {
                    onChange({ flatId: img.id, url: img.url });
                    backSearchParam(SEARCH.openImage);
                  }}
                  key={img.id}
                  src={img.url}
                  onError={() => setError(true)}
                />
              ))}
              {values.svg && (
                <NoImgWrap
                  onClick={() => {
                    onChange(null);
                    backSearchParam(SEARCH.openImage);
                  }}
                >
                  <FlatImg img={values.svg} size={110} />
                  <NoImgIco />
                </NoImgWrap>
              )}
            </Results>
          </>
        )}
      </DialogFullWindow>
    </Wrap>
  );
};

export default CardImgPicker;
