import { IconButton } from "@mui/material";
import styled from "styled-components/macro";
import { useViewerQuery } from "../../../queries/viewerQuery";
import { devicePaddings, isDesktop } from "../../../tools/device";
import { useNavigate } from "react-router";
import CloseIcon from "@mui/icons-material/Close";
import { useSeeHint } from "components/hint/Hint";
import { FIRSTSUBSCRIPTION } from "root/tutorial/misc/FirstSubscription";
import { useSubscriptions } from "./subscriptionHooks";
import { ManageSubscriptionButton } from "./ManageSubscriptionButton";
import { SubscriptionAlreadyPurchased } from "./SubscriptionAlreadyPurchased";
import { SubscriptionFooter } from "./SubscriptionFooter";
import { Trans } from "react-i18next";
import SubscriptionText from "./SubscriptionText";
import PriceButtons from "./PriceButtons";
import React from "react";
import PriceButton from "./PriceButton";
import Famembers from "./Famembers";
import { StoreId, useStoreIds } from "./subscriptionIds";
import SubscribedUntil from "./SubscribedUntil";
import SaleDialog from "./SaleDialog";
import { TUTORIALDONE } from "../../tutorial/steps/Verdict";
import { MOTIVATIONHINT } from "../../tutorial/setting/FeaturesHint";
import { useSetFlag } from "../../../queries/users/setFlag";
import SubscriptionPromocode from "./SubscriptionPromocode";

const { top, bottom } = devicePaddings();

const Wrap = styled.div`
  text-align: center;
  max-width: ${({ theme }) => theme.duo.maxWidth.narrow};
  width: 100%;
  margin-inline: auto;
  padding: 0;
  height: 100%;
`;
const Header = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
`;
const Content = styled.div`
  padding: ${top}px 5px ${93 + bottom}px 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: white;
  border-radius: 4px;
  overflow: auto;
  min-height: ${isDesktop() ? "auto" : "110%"};
  height: ${isDesktop() ? "100%" : "auto"};
`;
const Top = styled.div`
  color: #333;
  text-align: center;
  font-family:
    Noto Sans,
    Arial;
  font-size: 42px;
  font-style: normal;
  font-weight: 600;
  line-height: 50px;
  letter-spacing: -0.32px;
  margin: 0 0 20px;
`;
const BlueTop = styled.div`
  color: #09d;
`;
const SIconButton = styled(IconButton)`
  background: white;
  border-radius: 50px;
  z-index: 1;
  svg {
    width: 22px;
    height: 22px;
    color: #bbb;
  }
`;
const SubButtonWrapper = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  z-index: 10; // above manage and restore buttons
`;
const ButtonIn = styled.div`
  max-width: ${({ theme }) => theme.duo.maxWidth.narrow};
  margin-inline: auto;
  padding: 0 20px ${bottom + 5}px;
  background: white;
  border-top: 1px solid ${({ theme }) => theme.duo.color.grey};
`;

const SubscriptionScreen = () => {
  const {
    viewer,
    hintWasSeen,
    subscriptionFlags: {
      hasActiveFamilySub,
      hasActiveMonthlySub,
      hasActiveYearlySub,
      isFamilyFather,
      hasActiveFriendsReward
    },
    getFlag
  } = useViewerQuery();
  const navigate = useNavigate();
  const seeHint = useSeeHint();
  const { store } = useSubscriptions();
  const { storeIdYear, storeIdFamily, storeIdMonth } = useStoreIds();
  const [plan, setPlan] = React.useState<StoreId>(hasActiveFamilySub ? storeIdFamily : storeIdYear);
  const { subscriptions } = useSubscriptions();
  const timeSpentRef = React.useRef<number>(Date.now());
  const setFlag = useSetFlag();

  const handleCloseClick = () => {
    if (!hintWasSeen(TUTORIALDONE) && hintWasSeen(MOTIVATIONHINT)) {
      setFlag({ name: "myTimeSpentSubscription", value: String(Date.now() - timeSpentRef.current) });
    }

    seeHint(FIRSTSUBSCRIPTION);
    navigate(-1);
  };

  const isYearlyActive = plan === storeIdYear && hasActiveYearlySub;
  const isMonthlyActive = plan === storeIdMonth && hasActiveMonthlySub;

  const highPriceWithSale = getFlag("abtest_highPriceWithSale")?.value === "on";

  const SubButton = () => {
    if (isYearlyActive || isMonthlyActive) {
      return <PriceButton bought fullWidth />;
    } else if ((subscriptions && !subscriptions[plan]?.canPurchase) || (plan === storeIdMonth && hasActiveYearlySub)) {
      return <PriceButton notAvailable fullWidth />;
    } else if (!subscriptions) {
      return <PriceButton notInBrowser fullWidth />;
    } else {
      return <PriceButton storeId={plan} fullWidth />;
    }
  };

  return (
    <Wrap>
      <Content>
        <Header>
          <SIconButton onClick={handleCloseClick} color="inherit">
            <CloseIcon />
          </SIconButton>
        </Header>

        <Trans parent={Top}>
          Unlock <BlueTop>Unlimited</BlueTop> Access
        </Trans>

        <SubscribedUntil />
        {isFamilyFather ? (
          <Famembers />
        ) : (
          <>
            {subscriptions && <PriceButtons setPlan={setPlan} plan={plan} />}
            <SubscriptionText />
            <SubscriptionPromocode />
          </>
        )}

        <SubButtonWrapper>
          <ButtonIn>
            <SubButton />
          </ButtonIn>
        </SubButtonWrapper>

        <div>
          {!hasActiveFriendsReward && viewer?.subscription && store && <ManageSubscriptionButton />}
          <SubscriptionAlreadyPurchased />
          <SubscriptionFooter />
        </div>
      </Content>

      {highPriceWithSale && <SaleDialog />}
    </Wrap>
  );
};

export default SubscriptionScreen;
