import React from "react";
import styled from "styled-components/macro";
import { IconButton } from "@mui/material";
import DialogFullWindow from "components/other/DialogFullWindow";
import ProgressBar from "components/other/ProgressBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/pro-regular-svg-icons";
import { useTranslation } from "react-i18next";
import OnboardingButton from "./OnboardingButton";

const SDialogFullWindow = styled(DialogFullWindow)`
  background-color: ${({ theme }) => theme.duo.palette.blueVeryLight};
  height: 100vh;
  padding-bottom: 20px;
`;

const ModalContent = styled.div`
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    "header"
    "content"
    "button";
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.duo.palette.blueVeryLight};
`;

const Header = styled.div`
  grid-area: header;
  display: flex;
  width: 100%;
  align-items: center;
  padding: 20px 15px;
  gap: 10px;
`;

const Content = styled.div`
  grid-area: content;
  flex-grow: 1;
  width: 100%;
  overflow-y: hidden;
  justify-content: center;
  align-items: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  z-index: 2;
`;

const ProgressWrap = styled.div<{ $visible?: boolean }>`
  flex-grow: 1;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: ${({ $visible }) => ($visible ? "visible" : "hidden")};
`;

const BackButton = styled(IconButton)<{ $visible?: boolean }>`
  > svg {
    width: 20px;
    height: 20px;
  }
  visibility: ${({ $visible }) => ($visible ? "visible" : "hidden")};
  display: ${({ $visible }) => ($visible ? "inline-flex" : "none")};
`;

type Props = {
  showBackButton?: boolean;
  showProgressBar?: boolean;
  progressValue?: number;
  children: React.ReactNode;
  buttonText?: string;
  buttonOnClick?: () => void;
  buttonDisabled?: boolean;
  onBack?: () => void;
  showContinue?: boolean;
};

const OnboardingStep: React.FC<Props> = ({
  showBackButton = true,
  showProgressBar = true,
  progressValue = 10,
  children,
  buttonText = "Continue",
  buttonOnClick,
  buttonDisabled,
  showContinue = true,
  onBack
}) => {
  const { t } = useTranslation();

  return (
    <SDialogFullWindow open={true}>
      <ModalContent>
        <Header>
          <BackButton onClick={onBack} $visible={showBackButton}>
            <FontAwesomeIcon icon={faChevronLeft} />
          </BackButton>
          <ProgressWrap $visible={showProgressBar}>
            <ProgressBar value={progressValue} />
          </ProgressWrap>
        </Header>

        <Content>{children}</Content>

        {showContinue && (
          <OnboardingButton onClick={buttonOnClick} disabled={buttonDisabled}>
            {t(buttonText)}
          </OnboardingButton>
        )}
      </ModalContent>
    </SDialogFullWindow>
  );
};

export default OnboardingStep;
