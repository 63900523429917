import { isAndroid, isIos } from "tools/device";
import { useSubscriptions } from "./subscriptionHooks";

export type StoreId =
  | "year"
  | "3months"
  | "3months1"
  | "yearly"
  | "yearlyly"
  | "yearextra"
  | "family"
  | "yearly_cheap_apple"
  | "onemonth"
  // android offers:
  | "year@p1y"
  | "year@p1y@yeartrial"
  | "year@familyplan"
  | "year@familyplan@trial"
  | "year@onemonthplan"
  | "year@yearplan1"
  | "year@familyplan1"
  | "year@onemonthplan1"
  | "year@threemonthsplan"
  | "year@threemonthsplan1"
  // ios expensive
  | "familyextra"
  | "onemonth1";

enum Platform {
  android = "android",
  ios = "ios"
}

export const SUB: { id: StoreId; platform?: Platform; masterId?: "year" }[] = [
  { id: "3months" },
  { id: "3months1", platform: Platform.ios },
  { id: "year" },
  { id: "yearly" },
  { id: "yearlyly" },
  { id: "yearextra" },
  { id: "family" },
  { id: "onemonth" },
  { id: "yearly_cheap_apple", platform: Platform.ios }, // android equivalent was yearly-cheap
  // android offers:
  { id: "year@p1y", platform: Platform.android, masterId: "year" },
  { id: "year@p1y@yeartrial", platform: Platform.android, masterId: "year" },
  { id: "year@familyplan", platform: Platform.android, masterId: "year" },
  { id: "year@familyplan@trial", platform: Platform.android, masterId: "year" },
  { id: "year@onemonthplan", platform: Platform.android, masterId: "year" },
  { id: "year@yearplan1", platform: Platform.android, masterId: "year" },
  { id: "year@familyplan1", platform: Platform.android, masterId: "year" },
  { id: "year@onemonthplan1", platform: Platform.android, masterId: "year" },
  { id: "year@threemonthsplan", platform: Platform.android, masterId: "year" },
  { id: "year@threemonthsplan1", platform: Platform.android, masterId: "year" },
  // ios expensive:
  { id: "familyextra", platform: Platform.ios },
  { id: "onemonth1", platform: Platform.ios }
];

export const subsOnPlatform = () => {
  const platform = isIos() ? Platform.ios : Platform.android;
  return (
    // filter out android offers
    SUB.filter((s) => !s.masterId)
      //
      .filter((s) => s.platform === platform || !s.platform)
  );
};

export const is3months = (storeId: StoreId) => storeId.includes("3months") || storeId.includes("threemonths");

/**
 * NOTE: subscriptionModel and SubscriptionRegister rely on having %family% keyword in family storeId
 */

export const useStoreIds: () => { [key in "storeIdYear" | "storeIdFamily" | "storeIdMonth"]: StoreId } = () => {
  const { subscriptions } = useSubscriptions();

  // android - when trial is not available (user might be buying in past), it will be undefined and fallbacks on year
  // ios -  does not have different offers for trial
  const androidStoreIdYear = subscriptions?.["year@p1y@yeartrial"] ? "year@p1y@yeartrial" : "year@p1y";
  const androidStoreIdFamily = subscriptions?.["year@familyplan@trial"] ? "year@familyplan@trial" : "year@familyplan";

  return {
    storeIdYear: isAndroid() ? androidStoreIdYear : "year",
    storeIdFamily: isAndroid() ? androidStoreIdFamily : "family",
    storeIdMonth: isAndroid() ? "year@threemonthsplan" : "3months"
  };
};
