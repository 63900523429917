import LosingXps from "./LosingXps";
import { Quest } from "./Quest";

export function LosingXpsQuest() {
  return (
    <Quest questName="LosingXpsQuest" requiredExps={0}>
      <LosingXps />
    </Quest>
  );
}
