import React from "react";
import styled from "styled-components/macro";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import { CARD } from "../MainRouter";
import { useTranslateQuery } from "../../../queries/translateQuery";
import { TextField } from "../../../styled/TextField";
import TranslateButton from "./TranslateButton";
import { useDebounce, useEffectOnce, useKey, usePrevious } from "react-use";
import { CardValues } from "../../../components/ReduxProvider";
import { Trans, useTranslation } from "react-i18next";
import { useIncAdStack } from "../../../components/ads/adStack";
import Examples from "./Examples";
import { useMatch, useParams } from "react-router";
import CloseIcon from "@mui/icons-material/Close";
import { trim } from "lodash";
import BatchImport from "./BatchImport";
import { SOURCEFORM } from "../../library/library/libraryUrls";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import CardImgPicker from "./CardImgPicker";
import { useViewerDecks, useViewerQuery } from "../../../queries/viewerQuery";
import { useMutation, useQuery } from "relay-hooks";
import { graphql } from "babel-plugin-relay/macro";
import { CardFormWordQuery } from "./__generated__/CardFormWordQuery.graphql";
import FormMore from "./FormMore";
import FormTheory from "./FormTheory";
import BySharing2 from "root/tutorial/setting/BySharing2";
import { dispatchEvent, EVENT, snackbar } from "../../../tools/events";
import ClearButton from "./ClearButton";
import DuplicatedCard from "./DuplicatedCard";
import GrammarDictionary from "components/GrammarDictionary/GrammarDictionary";
import { resetCardMutation } from "../../../queries/cards/__generated__/resetCardMutation.graphql";
import { resetCardQL } from "../../../queries/cards/resetCard";
import { LabelTextWithLangLabel, LangLabel } from "tools/langs";
import HintTooltip from "components/other/HintTooltip";
import { OWNWORDQUEST, useCreatingNewCard } from "../main/quests/everySource/OwnWord";
import { SHOW_SOURCES_QUEST } from "../main/quests/everySource/HelpFindCave";

const wordQuery = graphql`
  query CardFormWordQuery(
    $checkForDuplicates: Boolean!
    $cardId: ID!
    $deckId: ID!
    $word: String!
    $lang: String!
    $nativeLang: String!
  ) {
    existsInDeck(cardId: $cardId, deckId: $deckId, word: $word, lang: $lang) @include(if: $checkForDuplicates) {
      id
      front
      back
      flipped
      hint
      sourceId
      sCardId
      svg {
        flatId
        url
      }
    }
    dictionary(word: $word, lang: $lang, nativeLang: $nativeLang) {
      examples
      grammar {
        id
        lang
        grammar
      }
    }
  }
`;

export const ADDCARDID = "addCard";
export const FRONT_ID = "frontId";

const SaveButton = styled(Button)`
  &&& {
    margin: 5px 20px;
    width: calc(100% - 40px);
  }
  &:disabled {
    background: ${({ theme }) => theme.duo.color.primaryGradient};
    color: ${({ theme }) => theme.duo.color.white};
    opacity: 0.5;
  }
`;
const DelButton = styled(Button)`
  &&& {
    float: left;
    margin: 8px 0 -30px;
    color: #888;
  }
`;
const Importing = styled.div`
  display: flex;
  color: ${({ theme }) => theme.duo.color.primary};
  font-weight: bold;
  font-size: ${({ theme }) => theme.duo.fontSize.small};
  margin: 0 0 10px;
  justify-content: center;
  align-items: center;
  svg {
    margin: 0 5px;
    font-size: 18px;
    cursor: pointer;
    color: grey;
  }
`;
const SGrammarDictionary = styled(GrammarDictionary)`
  margin: 40px 0 20px 0;
`;

type Props = {
  card?: CardValues;
  fromLang: string;
  toLang?: string | null;
  onSave: (
    values: CardValues,
    opts: { definitionApplied?: boolean; fromSharing?: boolean }
  ) => false | Promise<any> | void;
  withTheory?: boolean;
  withoutSorter?: boolean;
  onValuesChange?: (values: CardValues) => void;
  checkForDuplicates?: boolean;
  disableGrammar?: boolean;
  buttonText?: string;
};

const CardForm: React.FC<Props> = ({
  onValuesChange,
  onSave,
  checkForDuplicates,
  disableGrammar,
  withoutSorter,
  withTheory,
  card,
  fromLang,
  toLang,
  buttonText
}) => {
  const { viewer, hintWasSeen } = useViewerQuery();
  const navigate = useNavigate();
  const urlParams = useParams<{ term: string }>();
  const term = urlParams[CARD.termUrlParam];
  const { t } = useTranslation();
  const incAdStack = useIncAdStack();
  const inSCardsForm = useMatch(SOURCEFORM.query());

  const [toTranslate, setToTranslate] = React.useState<{
    side: "front" | "back";
    value: string;
    onlyImage?: boolean;
  }>();
  const [translating, setTranslating] = React.useState<"front" | "back" | null>();
  const [importRows, setImportRows] = React.useState<{ front: string; back: string; hint: string }[]>();
  const [importIndex, setImportIndex] = React.useState(0);
  const [moreRefreshKey, setMoreRefreshKey] = React.useState(0);
  const [saved, setSaved] = React.useState(false);
  const [processing, setProcessing] = React.useState(false);
  const [fromSharing, setFromSharing] = React.useState(false);
  const frontRef: React.RefObject<HTMLDivElement> = React.useRef(null);
  const [definitionApplied, setDefinitionApplied] = React.useState(false);
  const [resetCard] = useMutation<resetCardMutation>(resetCardQL);
  const creatingNewCard = useCreatingNewCard();

  const init = card || {
    front: term || "",
    back: "",
    hint: ""
  };
  const [values, setValuesRaw] = React.useState<CardValues>(init);
  const setValues = React.useCallback(
    (newValues: CardValues) => {
      onValuesChange && onValuesChange(newValues);
      setValuesRaw({
        ...newValues,
        sourceId: newValues.sourceId === undefined ? values.sourceId : newValues.sourceId,
        noDeck: newValues.noDeck === undefined ? values.noDeck : newValues.noDeck
      });
      toTranslate?.value && setToTranslate(undefined);
    },
    [onValuesChange, setValuesRaw, values, toTranslate?.value]
  );
  const [debouncedValues, setDebouncedValues] = React.useState<CardValues>(init);
  const [translated, setTranslated] = React.useState({ front: "", back: "" });
  const { deck } = useViewerDecks();

  const { data: wordData, isLoading: wordLoading } = useQuery<CardFormWordQuery>(
    wordQuery,
    {
      checkForDuplicates: !!checkForDuplicates,
      cardId: card && card.id ? (card.id as string) : "",
      deckId: deck?.id || "",
      word: debouncedValues.front,
      lang: fromLang,
      nativeLang: deck?.back || "en"
    },
    { skip: !debouncedValues.front || !deck?.id, fetchPolicy: "network-only" }
  );

  const duplicatedCard = wordData?.existsInDeck;
  const examples = wordData?.dictionary?.examples || undefined;
  const grammar = wordData?.dictionary?.grammar || null;
  const disabled =
    !values.front ||
    !values.back ||
    processing ||
    wordLoading ||
    (duplicatedCard !== undefined && duplicatedCard !== null);

  const ops =
    toTranslate?.side === "front" ? { fromLang: fromLang, toLang: toLang } : { fromLang: toLang, toLang: fromLang };
  const from = (toTranslate?.value || "").replace(/([([].*)[)\]]/g, "").trim();
  const {
    translate: apiTranslate,
    svg,
    isLoading
  } = useTranslateQuery(toTranslate?.value && toLang ? { from, ...ops, withSvg: true } : undefined);
  const prevApiTrans = usePrevious(apiTranslate);
  React.useEffect(() => {
    if (apiTranslate && apiTranslate !== prevApiTrans && toTranslate) {
      const toSide = toTranslate?.side === "front" ? "back" : "front";
      const translationIfNotImporting = toTranslate.onlyImage && values[toSide] ? values[toSide] : apiTranslate;
      setValues({ ...values, [toSide]: translationIfNotImporting, svg });
      if (toSide === "front") {
        setDebouncedValues({ ...debouncedValues, front: apiTranslate });
      } else {
        setDebouncedValues({ ...debouncedValues, back: apiTranslate });
      }
      setTranslated({ [toTranslate.side]: "", [toSide]: apiTranslate } as any);
      setTranslating(null);
    }
  }, [apiTranslate, toTranslate, values, prevApiTrans, debouncedValues, svg, setValues]);

  React.useEffect(() => {
    setTranslated({ front: "", back: "" });
    setToTranslate(undefined);
  }, [fromLang, toLang]);

  React.useEffect(() => {
    if (term && toTranslate?.value !== term) {
      setFromSharing(true);
      setValues({ front: term, back: "", hint: "" });
      setToTranslate({ value: term, side: "front" });
      setTranslating("front");
      setDebouncedValues({ ...debouncedValues, front: term });
      navigate(CARD.url(), { replace: true });
    }
  }, [term, toTranslate?.value, navigate, debouncedValues, setValues]);

  React.useEffect(() => {
    if (!values.back && definitionApplied) setDefinitionApplied(false);
  }, [values.back, definitionApplied]);

  useEffectOnce(() => {
    !inSCardsForm && !creatingNewCard && setTimeout(() => frontRef.current?.focus());
  });

  // stop progpagation spacebar, which would be captured by YT iframe in background otherwise
  useKey(" ", (e) => e.stopPropagation(), { options: { capture: true } });

  useDebounce(
    () => {
      if (!definitionApplied) {
        if (debouncedValues.front !== values.front && (!values.back || values.back === translated.back)) {
          translate("front");
        }
        if (debouncedValues.back !== values.back && (!values.front || values.front === translated.front)) {
          translate("back");
        }
      }
      setDebouncedValues(values);
      setProcessing(false);
    },
    600,
    [values, definitionApplied]
  );

  const translate = (side: "front" | "back") => {
    if (toTranslate?.side !== side || (values[side] && toTranslate?.value !== values[side])) {
      incAdStack(2);
      setToTranslate({ side, value: values[side] });
      setTranslating(side);
    }
    setDefinitionApplied(false);
  };

  const initForm = () => {
    setImportRows(undefined);
    setValues(init);
    setImportIndex(0);
    setDebouncedValues(init);
    !importRows && frontRef.current?.focus();
  };

  const setValuesForImport = (values: CardValues, startImport?: boolean) => {
    setValues(values);
    setToTranslate({ side: "front", value: values.front, onlyImage: true });
    setTranslating("front");
    if (startImport) {
      setImportIndex(0);
    } else {
      setImportIndex(importIndex + 1);
    }
  };

  const importNextOrInit = () => {
    if (importRows && importRows[importIndex + 1]) {
      setValuesForImport(importRows![importIndex + 1]);
    } else {
      initForm();
    }
    setFromSharing(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (disabled) return;
    const saveResult = onSave(
      {
        ...values,
        front: values.front.trim(),
        back: values.back.trim(),
        hint: (values.hint || "").trim(),
        theory: (values.theory || "").trim(),
        svg: values.svg ? values.svg : null
      },
      { definitionApplied, fromSharing }
    );
    if (saveResult !== false) {
      incAdStack(5);
      setMoreRefreshKey(moreRefreshKey + 1);
      !values.id && importNextOrInit();
    }
    setSaved(true);
  };

  const handleChange = (e) => {
    setProcessing(true);
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleDuplicatedCardEdit = () => {
    if (duplicatedCard) {
      dispatchEvent(EVENT.openCardEditDialog, { ...duplicatedCard, cardEditCallback: card?.cardEditCallback });
      setValues({ ...init });
    }
  };

  const handleResetProgress = () => {
    if (duplicatedCard) {
      setValues({ ...init });
      resetCard({
        variables: { id: duplicatedCard?.id as string },
        onCompleted: () => {
          snackbar(t("Progress has been reset. Card will reappear in your learning deck."), {
            bottom: true,
            autoHide: 4000
          });
        }
      });
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLDivElement>) => {
    const text = e.clipboardData.getData("Text");
    const rows = text
      .split(/(?:\r\n|\r|\n)/)
      .filter((row) => !!row.trim())
      .map(trim);
    const columnDelimiter = text.includes("\t")
      ? "\t"
      : rows.filter((r) => r.includes(",")).length > rows.length / 2
        ? ","
        : "{{none}}";
    if (rows.length > 1 && !values.id) {
      e.preventDefault();
      const importedRows = rows.map((r) => {
        const pcs = r.split(columnDelimiter).map(
          (pc) =>
            // @ts-ignore
            trim(pc, /-_"' /)
              // c0/c1 control codes
              .replace(/[\u0000-\u001F]/g, "") // eslint-disable-line
        );
        return { front: pcs[0], back: pcs[1] || "", hint: pcs[2] || "" };
      });
      setImportRows(importedRows);
      setValuesForImport(importedRows[0], true);
    }
  };

  const swap = () => {
    setValues({ ...values, front: values.back, back: values.front });
    setImportRows(importRows?.map((r) => ({ ...r, front: r.back, back: r.front })));
  };

  return (
    <form autoComplete="off" onSubmit={(e) => handleSubmit(e)}>
      <CardImgPicker
        onChange={(svg) => setValues({ ...values, svg })}
        values={values}
        disabled={disabled}
        langs={[...(toLang ? [toLang] : []), fromLang]}
      />

      {importRows && (
        <Importing>
          <Trans>Import</Trans> {importIndex + 1} / {importRows?.length} &nbsp;
          <CloseIcon onClick={initForm} />
          <SwapVertIcon onClick={swap} />
        </Importing>
      )}
      <FormMore
        lang={fromLang}
        langNative={toLang || undefined}
        sourceId={card?.sourceId || undefined}
        values={values}
        onValuesChange={(newValues) => setValues({ ...values, ...newValues })}
        withoutSorter={withoutSorter}
        key={moreRefreshKey}
        allowNoDeck={!card?.id}
        saved={saved}
      />
      {withTheory && (
        <FormTheory values={values} onValuesChange={(newValues) => setValues({ ...values, ...newValues })} />
      )}
      <HintTooltip
        title="Type a word and it will translate itself."
        name="ownWordForm"
        canOpen={hintWasSeen(SHOW_SOURCES_QUEST) && !hintWasSeen(OWNWORDQUEST)}
      >
        <div style={{ width: "100%", height: "1px" }} />
      </HintTooltip>
      <TextField
        name="front"
        id={FRONT_ID}
        label={<LangLabel lang={fromLang} />}
        value={values.front}
        onChange={handleChange}
        onPaste={handlePaste}
        inputProps={{
          // longer because of app_translates
          maxLength: viewer?.admin ? 250 : 150,
          ref: frontRef
        }}
        InputProps={{
          endAdornment: (
            <>
              {!values.id && !values.front && <BatchImport />}
              <TranslateButton
                value={values.front}
                translated={translated.front}
                translating={translating === "front" && isLoading}
                onClick={() => translate("front")}
              />
            </>
          )
        }}
      />
      {duplicatedCard && (
        <DuplicatedCard
          onEditOriginal={handleDuplicatedCardEdit}
          onResetProgress={handleResetProgress}
          word={values.front}
        />
      )}
      <TextField
        name="back"
        label={
          <>
            <span style={definitionApplied ? { textDecoration: "line-through" } : undefined}>
              {toLang && <LangLabel lang={toLang} />}
            </span>
            {definitionApplied && " " + t("Definition")}
          </>
        }
        value={values.back}
        onChange={handleChange}
        inputProps={{
          maxLength: viewer?.admin ? 250 : 150,
          style: {
            transition: "400ms transform",
            transform: definitionApplied ? "scale(1.05)" : "none"
          }
        }}
        InputProps={{
          endAdornment: !definitionApplied ? (
            <TranslateButton
              value={values.back}
              translated={translated.back}
              translating={translating === "back" && isLoading}
              onClick={() => translate("back")}
            />
          ) : (
            <ClearButton
              onClick={() => {
                setValues({ ...values, back: translated.back || "" });
                setDefinitionApplied(false);
              }}
            />
          )
        }}
      />
      <TextField
        multiline
        name="hint"
        label={<LabelTextWithLangLabel label={t("Example of usage")} lang={fromLang} />}
        value={values.hint === null ? undefined : values.hint}
        onChange={handleChange}
        inputProps={{ maxLength: 250 }}
      />
      <Examples
        loading={wordLoading}
        collapsed={!!values.hint}
        examples={examples}
        lang={fromLang}
        onPick={(hint) => setValues({ ...values, hint })}
      />
      <SaveButton disabled={disabled} variant="contained" color="primary" type="submit" fullWidth id={ADDCARDID}>
        {buttonText ? buttonText : t("Save")}
      </SaveButton>
      {importRows && <DelButton onClick={importNextOrInit}>{t("Skip")}</DelButton>}
      <button style={{ display: "none" }} type="submit"></button>
      {fromSharing && <BySharing2 />}

      {!disableGrammar && grammar && <SGrammarDictionary grammar={grammar} />}
    </form>
  );
};

export default CardForm;
