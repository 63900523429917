import { Quest } from "../Quest";
import OwnWord from "./OwnWord";

export function OwnWordQuest() {
  return (
    <Quest questName="OwnWordQuest" requiredExps={0}>
      <OwnWord />
    </Quest>
  );
}
