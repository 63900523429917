import { useViewerQuery } from "queries/viewerQuery";
import React from "react";
import TutorialA from "./Tutorial";
import TutorialB from "./TutorialB";
const Tutorial: React.FC<{}> = () => {
  const { getFlag } = useViewerQuery();
  const newOnboarding = getFlag("abtest_onboarding")?.value === "on";

  return newOnboarding ? <TutorialB /> : <TutorialA />;
};

export default Tutorial;
