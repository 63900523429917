import { useViewerDecks, useViewerQuery } from "queries/viewerQuery";
import { langs } from "../../sharedJs__generated/langsList";
import { Howler } from "howler";

// https://stackoverflow.com/a/34842797/844628
export const hashCode = (s: string, voiceId?: string) =>
  `h${s.split("").reduce((a, b) => ((a << 5) - a + b.charCodeAt(0)) | 0, 0) + (voiceId || "")}`;

export const canTts = (lang?: string, additionalLangs?: string[]) =>
  !!additionalLangs && !!lang
    ? [lang, ...additionalLangs].every((l) => langs[l]?.multiLangTts)
    : !!lang && !!langs[lang] && langs[lang].tts !== false;

export const silenceTts = () => {
  Howler.stop();
  Howler.unload();
};

/**
 * Returns array of voices which starts by next voice after lastVoices
 */
export const usePickVoices = (lang?: string, lastVoice?: string) => {
  const { decks } = useViewerDecks();
  const { viewer } = useViewerQuery();

  let voices = decks?.find((d) => d.front === lang)?.voices?.map((v) => v.id);
  if (!voices || !viewer?.subscription) {
    return undefined;
  }
  const lastVoiceInd = voices?.findIndex((v) => v === lastVoice);
  if (!lastVoice || lastVoiceInd === -1 || lastVoiceInd === voices.length - 1) {
    return voices;
  }
  const rotateByElements = lastVoiceInd + 1;
  return voices.concat(voices.splice(0, rotateByElements)); // src: https://stackoverflow.com/a/1521121/844628
};
