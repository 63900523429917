import { BackButton, Layout } from "components/header/Header";
import React from "react";
import styled from "styled-components/macro";
import Video from "./video/Video";
import { sourceQuery$data } from "queries/sources/__generated__/sourceQuery.graphql";
import { useViewerQuery } from "queries/viewerQuery";
import { FIVEHINT } from "root/main/main/Main";
import SourceDots from "./SourceDots";
import Fire from "components/fire/Fire";
import { useNavigate } from "react-router";
import { useLibraryUrl } from "root/RootRouter";
import { devicePaddings } from "tools/device";
import { useInterval } from "react-use";
import { useBurnWithSource } from "components/fire/xpHooks";

const { top } = devicePaddings();
const BAR_HEIGHT = 50 + top;

const Wrap = styled.div`
  display: flex;
  position: fixed;
  flex-direction: column;
  height: calc(100% - ${BAR_HEIGHT}px);
  width: 100%;
  max-width: ${({ theme }) => theme.duo.maxWidth.narrow};
`;
const BlackBar = styled.div<{ $faded: boolean }>`
  display: flex;
  justify-content: space-between;
  padding: ${top + 2}px 10px 0;
  background: black;
  height: ${BAR_HEIGHT}px;
  svg {
    color: ${({ $faded }) => ($faded ? "grey" : "white")};
  }
`;
const Right = styled.div`
  display: flex;
  align-items: center;
`;

type Props = { source: NonNullable<sourceQuery$data["sourceByUriOrId"]> };

const VideoWrap: React.FC<Props> = ({ source }) => {
  const [showHeader, setShowHeader] = React.useState(true);
  const { hintWasSeen } = useViewerQuery();
  const navigate = useNavigate();
  const libraryUrl = useLibraryUrl();
  const moveRef = React.useRef(0);
  const [moveIndicator, setMoveIndicator] = React.useState(0);
  const burnW = useBurnWithSource(source.lang);

  useInterval(() => {
    if (moveIndicator !== moveRef.current) {
      setMoveIndicator(moveRef.current);
      burnW();
    }
  }, 1500);

  const handleMove = () => (moveRef.current = Math.random());

  React.useEffect(() => {
    window.addEventListener("wheel", handleMove, false);
    return () => window.removeEventListener("wheel", handleMove);
  });

  return (
    <Layout narrow>
      <BlackBar $faded={!showHeader}>
        <BackButton onBack={() => navigate(libraryUrl, { replace: true })} />
        {showHeader && hintWasSeen(FIVEHINT) && (
          <Right>
            <SourceDots source={source} />
            <Fire />
          </Right>
        )}
      </BlackBar>
      <Wrap onMouseMove={handleMove} onTouchMove={handleMove}>
        <Video source={source} onShowHeader={(show) => setShowHeader(show)} />
      </Wrap>
    </Layout>
  );
};

export default VideoWrap;
