import { useGetState, useSetState } from "../../../../components/ReduxProvider";
import { useEffect } from "react";
import { useViewerXps } from "../../../../queries/viewerQuery";
import { MotivationBubble } from "./MotivationBubble";
import { dispatchEvent, EVENT } from "../../../../tools/events";

type Props = {
  questName: string;
  requiredExps: number;
  children: JSX.Element;
};

export function Quest(props: Props) {
  const currentQuest = useGetState("currentQuest");
  const setCurrentQuest = useSetState("currentQuest");
  const setShowQuestsBackdrop = useSetState("showQuestsBackdrop");

  const { xps } = useViewerXps();

  useEffect(() => {
    if (xps && currentQuest?.name !== props.questName) {
      setCurrentQuest({
        name: props.questName,
        startXps: xps.total + props.requiredExps
      });
      setShowQuestsBackdrop(false);
      dispatchEvent(EVENT.updateMain);
    }
  }, [currentQuest?.name, props.questName, props.requiredExps, setCurrentQuest, setShowQuestsBackdrop, xps]);

  if (!xps || !currentQuest || currentQuest.name !== props.questName) {
    return null;
  }

  if (xps.total < currentQuest.startXps) {
    return <MotivationBubble />;
  }

  return props.children;
}
