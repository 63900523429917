import React from "react";
import { INVITE_FRIEND_QUEST_PHASE_1, INVITE_FRIEND_QUEST_PHASE_2 } from "./InviteFriendQuest.model";
import { useViewerQuery } from "../../../../../queries/viewerQuery";
import { InviteFriendQuestPhase1 } from "./InviteFriendQuestPhase1";
import { InviteFriendQuestPhase2 } from "./InviteFriendQuestPhase2";
import { InviteFriendQuestPhase3 } from "./InviteFriendQuestPhase3";
import { Quest } from "../Quest";

const InviteFriendQuest = () => {
  const { hintWasSeen } = useViewerQuery();

  const render = () => {
    if (!hintWasSeen(INVITE_FRIEND_QUEST_PHASE_1)) {
      return <InviteFriendQuestPhase1 />;
    }

    if (!hintWasSeen(INVITE_FRIEND_QUEST_PHASE_2)) {
      return <InviteFriendQuestPhase2 />;
    }

    return <InviteFriendQuestPhase3 />;
  };

  return (
    <Quest questName="InviteFriendQuest" requiredExps={1}>
      {render()}
    </Quest>
  );
};

export default InviteFriendQuest;
