import { Button, Paper } from "@mui/material";
import React from "react";
import { Trans } from "react-i18next";
import { useNavigate } from "react-router";
import styled from "styled-components/macro";
import DialogWindow from "../../../components/other/DialogWindow";
import { secToTime } from "../../../tools/dateFns";
import { ytimg } from "../../../tools/unsplash";
import { SOURCE } from "../library/libraryUrls";

const Wrap = styled(Paper)`
  display: flex;
  align-items: center;
  margin: 8px 0;
  position: relative;
  cursor: pointer;
`;
const Bottom = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 159px;
  font-size: 11px;
  font-weight: bold;
  color: white;
  line-height: 1.5em;
`;
const Duration = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  background: #000b;
  padding: 0 7px;
  border-radius: 4px;
`;
const Published = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  background: orange;
  padding: 0 7px;
  border-radius: 4px;
`;
const Img = styled.img`
  width: 160px;
  height: 90px;
`;
const In = styled.div`
  font-size: ${({ theme }) => theme.duo.fontSize.small};
  line-height: 1.3em;
  width: calc(100% - 160px);
  padding: 10px;
`;
const Title = styled.div`
  font-weight: bold;
  margin-bottom: 4px;
  display: -webkit-box;
  line-height: 16px;
  max-height: 48px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const DialogWrap = styled.div`
  margin: -10px 0 10px;
`;

export type VideoType = {
  videoId: string;
  title: string;
  videoLang: string;
  description: string;
  duration: number;
  existingUri: string | null;
};
type Props = {
  video: VideoType;
  onPick: VoidFunction;
};

const VideoItem: React.FC<Props> = ({ video, onPick }) => {
  const [openPublished, setOpenPublished] = React.useState(false);
  const navigate = useNavigate();

  const handleClick = () => {
    if (video.existingUri) {
      setOpenPublished(true);
    } else {
      onPick();
    }
  };

  return (
    <>
      <Wrap onClick={handleClick}>
        <Bottom>
          {video.existingUri && (
            <Published>
              <Trans>Published</Trans>
            </Published>
          )}
          <Duration>{secToTime(video.duration)}</Duration>
        </Bottom>
        <Img alt={video.title} src={ytimg(video.videoId)} />
        <In>
          <Title>{video.title}</Title>
          {/* <Description>{video.description}</Description> */}
        </In>
      </Wrap>

      <DialogWindow open={openPublished} onClose={() => setOpenPublished(false)}>
        <DialogWrap>
          <Trans parent="p">Someone already published this video.</Trans>
          <Button color="primary" variant="contained" onClick={() => navigate(SOURCE.url(video.existingUri as string))}>
            <Trans>watch video</Trans>
          </Button>
        </DialogWrap>
      </DialogWindow>
    </>
  );
};

export default VideoItem;
