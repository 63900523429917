import { IconButton } from "@mui/material";
import React from "react";
import styled from "styled-components/macro";
import { useTtsQuery } from "queries/ttsQuery";
import { useLongPress, useUnmount, useMount } from "react-use";
import { useSeeHint } from "../hint/Hint";
import { hashCode, silenceTts } from "queries/tts/ttsTools";
import { LONGPRESSHINT, LONGPRESSHINT2 } from "../picker/SpeechHintTooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVolume, faSpinner } from "@fortawesome/pro-regular-svg-icons";

const Wrap = styled.span`
  width: 16px;
  height: 1em;
  display: inline-block;
  user-select: none;
  /* margin-top: -12px; */
`;
const SIconButton = styled(IconButton)<{ $small?: boolean }>`
  padding: ${({ $small }) => ($small ? "12px" : "15px")};
  margin: ${({ $small }) => ($small ? "-4px 0 0 -23px;" : "-4px 0 0 -34px;")};
  color: ${({ theme }) => theme.duo.color.lightBlue};
  transition:
    color 1s,
    transform 0.8s;
  svg {
    width: ${({ $small }) => ($small ? "20" : "26")}px;
    height: ${({ $small }) => ($small ? "20" : "26")}px;
  }
`;

type Props = {
  text?: string;
  lang?: string;
  additionalLangs?: string[]; // currently not used, multilang providers not good enough or pricey
  small?: boolean;
  className?: string;
  voiceId?: string;
  hash?: string;
  useServerCache?: boolean;
  onClick?: VoidFunction;
  style?: React.CSSProperties;
  playOnMount?: boolean;
};

const Speech: React.FC<Props> = React.memo(
  ({ voiceId, onClick, lang, additionalLangs, style, playOnMount, useServerCache = true, ...p }) => {
    const seeHint = useSeeHint();
    const [playing, setPlaying] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [pressing, setPressing] = React.useState(false);

    useMount(() => {
      if (playOnMount) {
        playTTS();
      }
    });

    useUnmount(() => {
      if (playing) {
        silenceTts();
      }
    });

    const tts = useTtsQuery(lang, additionalLangs);
    const cursorCoords = React.useRef({
      startX: 0,
      startY: 0,
      endX: 0,
      endY: 0
    });

    const getX = (e) => e.pageX || (e.touches?.[0] || e.changedTouches?.[0])?.pageX;
    const getY = (e) => e.pageY || (e.touches?.[0] || e.changedTouches?.[0])?.pageY;

    const playTTS = (speakingRate?: number) => {
      if (!tts || !p.text) return;
      setLoading(true);
      tts({
        text: p.text as string,
        speakingRate: speakingRate,
        voiceId,
        onStarted: () => {
          setPlaying(true);
          setLoading(false);
        },
        onEnded: () => {
          setPlaying(false);
          setLoading(false);
        },
        useServerCache
      });
    };

    const longPress = useLongPress(
      () => {
        const deltaX = Math.abs(cursorCoords.current.startX - cursorCoords.current.endX);
        const deltaY = Math.abs(cursorCoords.current.startY - cursorCoords.current.endY);
        const isDragging = deltaX > 30 || deltaY > 30;

        if (tts && !isDragging) {
          playTTS(0.6); // SLOW RATE
          seeHint(LONGPRESSHINT);
          seeHint(LONGPRESSHINT2);
        }
        setPressing(true);
      },
      {
        delay: 200,
        isPreventDefault: false
      }
    );

    if (!tts || !p.text) return null;

    const handlePlayClick = (e) => {
      if (!pressing) {
        playTTS(); // NORMAL RATE
      }
      onClick && onClick();
      e.stopPropagation();
    };

    const handleClick = (e) => {
      if (playing) {
        silenceTts();
      } else {
        handlePlayClick(e);
      }
    };

    const handleDown = (e) => {
      cursorCoords.current.startX = cursorCoords.current.endX = getX(e);
      cursorCoords.current.startY = cursorCoords.current.endY = getY(e);
      longPress.onMouseDown(e);
      setTimeout(() => (window as any).getSelection && (window as any).getSelection().removeAllRanges(), 500); // ios quirks
      setTimeout(() => (window as any).getSelection && (window as any).getSelection().removeAllRanges(), 550);
    };
    const handleUp = (e) => {
      setTimeout(() => setPressing(false), 50);
      (window as any).getSelection && (window as any).getSelection().removeAllRanges();
      longPress.onMouseUp();
    };

    const handleMove = (e) => {
      cursorCoords.current.endX = getX(e);
      cursorCoords.current.endY = getY(e);
    };

    return (
      <Wrap className={p.className} /*style={style}*/>
        <SIconButton
          onMouseDown={handleDown}
          onMouseMove={handleMove}
          onTouchMove={handleMove}
          onTouchStart={handleDown}
          onClick={handleClick}
          onMouseUp={handleUp}
          onTouchEnd={handleUp}
          onMouseLeave={handleUp}
          $small={p.small}
          color="primary"
          className={hashCode(p.text, voiceId)}
        >
          {loading ? (
            <FontAwesomeIcon icon={faSpinner} spin={true} />
          ) : (
            <FontAwesomeIcon icon={faVolume} beatFade={playing} />
          )}
        </SIconButton>
      </Wrap>
    );
  }
);

// WORKAROUND because in voiceItem I cannot declare this for unnown reason
export const SpeechForVoice = styled(Speech)`
  width: auto;
  margin: -11px 0 0 -3px;
  .MuiIconButton-root {
    padding: 0;
    margin: 0;
  }
  svg {
    width: 26px !important;
    height: 26px !important;
  }
`;

export default Speech;
