import { Fade, Paper } from "@mui/material";
import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";

const SPaper = styled(Paper)`
  position: relative;
  padding: 16px 18px 14px !important;
  margin: 8px auto -5px;
  z-index: 10;
  transition: all 1000ms ease-in-out;
`;
const Arrow = styled.div`
  position: absolute;
  bottom: -6px;
  left: calc(50% - 57px);
  width: 10px;
  height: 10px;
  border: ${(p) => p.theme.duo.border.grey};
  border-top: none;
  border-left: none;
  transform: rotate(45deg);
  background: white;
`;

type Props = {
  children: React.ReactNode;
  visible?: boolean;
};

export function QuestMessage({ children, visible = true }: Props) {
  const [delayedVisible, setDelayedVisible] = useState(true);

  useEffect(() => {
    if (!visible) {
      setTimeout(() => setDelayedVisible(false), 100);
    }
  }, [visible]);

  return (
    <Fade in={delayedVisible} appear timeout={{ enter: 1200, exit: 2500 }}>
      <SPaper elevation={8}>
        {children}
        <Arrow />
      </SPaper>
    </Fade>
  );
}
