import React from "react";
import { STREAK_FREEZE_QUEST } from "sharedJs__generated/constants";
import { useViewerQuery } from "../../../../../queries/viewerQuery";
import { StreakFreezeAssignment } from "./StreakFreezeAssignment";
import { StreakFreezeBubble } from "./StreakFreezeBubble";
import { Quest } from "../Quest";

const StreakFreezeQuest = () => {
  const { hintWasSeen } = useViewerQuery();

  return (
    <Quest questName="StreakFreezeQuest" requiredExps={0}>
      {!hintWasSeen(STREAK_FREEZE_QUEST) ? <StreakFreezeAssignment /> : <StreakFreezeBubble />}
    </Quest>
  );
};

export default StreakFreezeQuest;
