import React from "react";
import styled from "styled-components/macro";
import Row from "./Row";
import RowIn from "./RowIn";
import CardCircle, { CircleColor } from "./CardCircle";
import { IconButton, Menu, MenuItem, Stack } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useTranslation } from "react-i18next";
import Speech from "../speech/Speech";
import Theory from "../Theory/Theory";
import { TTheory } from "types/TheoryTypes";
import { genderStyle } from "tools/genderStyle";
import FlatImg from "./FlatImg";
import CardSourceIcons from "root/main/learn/CardSourceIcons";
import { Kind } from "queries/sources/__generated__/sourceQuery.graphql";
import { formatDistance } from "date-fns";
import { faRectangleVerticalHistory } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Waiting = styled.div<{ gold: boolean }>`
  color: ${({ theme, gold }) => (gold ? theme.duo.color.gold : theme.duo.color.primary)};
  font-size: ${({ theme }) => theme.duo.fontSize.smallest};
  line-height: 1.2em;
`;
const SRow = styled(Row)`
  padding: 9px 17px;
  cursor: default;
  position: relative;
`;
const ActionsButton = styled(IconButton)`
  padding: 12px 2px;
`;
const Fld = styled.span<{ revised?: boolean }>`
  color: ${({ revised, theme }) => (revised ? theme.duo.color.green : "inherit")};
`;
const SMenuItem = styled(MenuItem)`
  svg {
    margin-right: 5px;
  }
`;
const SSpeech = styled(Speech)`
  position: absolute;
  margin: -6px 0px 0px -28px;
  svg {
    width: 17px;
    height: 17px;
  }
  button {
    padding: 6px 5px 6px 5px;
    margin: 0px 0px 0 -1px;
  }
`;
const SRowIn = styled(RowIn)<{ padded?: boolean }>`
  padding-left: ${({ padded }) => (padded ? "10px" : 0)};
`;
const SFlatImg = styled(FlatImg)`
  svg,
  img {
    margin-right: 15px;
    padding: 4px;
  }
`;
const PlaceholderIcon = styled(FontAwesomeIcon)`
  color: white;
  background: #eaf2fa;
  border-radius: 50%;
  padding: 11px;
  margin: 3px 17px 3px 3px;

  && {
    width: 22px;
    height: 22px;
  }
`;
const TheoryWrap = styled.div`
  background: white;
  padding: 1px;
`;

type Props = {
  card: {
    id: string;
    source?: {
      kind: Kind;
      course: boolean;
    } | null;
    sourceId?: string | null;
    sCardId?: string | null;
    front: string;
    back?: React.ReactNode;
    hint?: string | null;
    theory: TTheory | null;
    waiting?: number | null;
    knownCount?: number;
    svg: { flatId: string; url: string } | null;
    revision?: {
      frontRe: string | null;
      backRe: string | null;
      hintRe: string | null;
      svg: { flatId: string; url: string } | null;
    } | null;
  };
  icon?: React.ReactNode;
  iconColor?: CircleColor;
  onClick?: VoidFunction;
  count?: number;
  actions?: { title: React.ReactNode; action?: VoidFunction }[];
  className?: string;
  sourceId?: string | null;
  speechLang?: string;
  mainScreenDesign?: boolean;
  style?: React.CSSProperties;
};

const CardItem: React.FC<Props> = ({
  card,
  onClick,
  count,
  icon,
  iconColor,
  actions,
  className,
  sourceId,
  speechLang,
  mainScreenDesign,
  style
}) => {
  const [open, setOpen] = React.useState(false);
  const anchorEl = React.useRef<HTMLButtonElement>(null);
  const { t } = useTranslation();

  const front = card.revision?.frontRe || card.front;
  const hint = card.revision?.hintRe || card.hint;
  const svg = card.revision?.svg || card.svg;

  const handleClick = () => {
    if (onClick) {
      onClick();
    } else {
      setOpen(true);
    }
  };

  return (
    <>
      {card.theory && (
        <TheoryWrap>
          <Theory theory={card.theory} collapsed={true} sourceId={sourceId} withDelete />
        </TheoryWrap>
      )}
      <SRow onClick={handleClick} className={className} style={style}>
        {icon && <CardCircle iconColor={iconColor} icon={icon} count={count} narrow={mainScreenDesign} />}
        {svg ? <SFlatImg img={svg} size={50} /> : <PlaceholderIcon icon={faRectangleVerticalHistory} />}
        <SRowIn
          padded={!!speechLang}
          lang={speechLang}
          title={
            <Fld revised={!!card.revision?.frontRe}>
              {speechLang && <SSpeech text={front} lang={speechLang} />}
              {speechLang ? genderStyle(front, speechLang) : front}
            </Fld>
          }
          text={<Fld revised={!!card.revision?.backRe}>{card.revision?.backRe || card.back}</Fld>}
          hint={
            <>
              {speechLang && hint && <SSpeech text={hint} lang={speechLang} />}
              <Fld revised={!!card.revision?.hintRe}>{hint}</Fld>
              {card.waiting && card.waiting > 0 && (
                <Waiting gold={Number(card.knownCount) >= 5}>
                  {formatDistance(0, card.waiting * 1000)} {t("until returns back")}
                </Waiting>
              )}
            </>
          }
        ></SRowIn>
        {actions && (
          <>
            <Stack direction="row" alignItems="center">
              {mainScreenDesign && card.sourceId && <CardSourceIcons card={card} mainScreenDesign></CardSourceIcons>}
              <ActionsButton
                ref={anchorEl}
                onClick={(e) => {
                  setOpen(true);
                  e.stopPropagation();
                }}
              >
                <MoreVertIcon />
              </ActionsButton>
            </Stack>
            <Menu
              anchorEl={anchorEl.current}
              open={open}
              onClose={(e) => {
                setOpen(false);
                (e as any).stopPropagation();
              }}
            >
              {actions.map((action, i) => (
                <SMenuItem
                  key={i}
                  onClick={(e) => {
                    action.action && action.action();
                    setOpen(false);
                    e.stopPropagation();
                  }}
                >
                  {action.title}
                </SMenuItem>
              ))}
            </Menu>
          </>
        )}
      </SRow>
    </>
  );
};

export default CardItem;
