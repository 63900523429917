import React from "react";
import styled, { keyframes, css } from "styled-components/macro";
import VoiceItem from "../../../components/speech/VoiceItem";
import { Menu, MenuItem, Switch } from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import DialogFullWindow from "../../../components/other/DialogFullWindow";
import { LearnHints } from "../learn/CardHints";
import { Trans } from "react-i18next";
import BottomIcon from "../card/BottomIcon";
import {
  selectPairingOff,
  selectRomanOff,
  setPairingOff,
  setRomanOff,
  useGetState
} from "../../../components/ReduxProvider";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useDispatch, useSelector } from "react-redux";
import { canTts } from "../../../queries/tts/ttsTools";
import VoicePickerItem from "../../../components/speech/VoicePickerItem";
import JapaneseAlphabets from "./JapaneseAlphabets";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/pro-regular-svg-icons";
import { usePrevious } from "react-use";

const SHAKEDURATION = 600;
const shakeAnimation = keyframes`
  0%, 100% { transform: translate(1px, 1px) rotate(0deg); }
  10%, 90% { transform: translate(-1px, -2px) rotate(-1deg); }
  20%, 80% { transform: translate(-3px, 0px) rotate(1deg); }
  30%, 70% { transform: translate(3px, 2px) rotate(0deg); }
  40%, 60% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
`;

const SDialogWindow = styled(DialogFullWindow)`
  padding-right: 20px;
  padding-left: 20px;
  text-align: center;
`;
const SBottomIcon = styled(BottomIcon)<{ $isShaking?: boolean }>`
  left: 61px;
  && svg {
    width: 17px;
    height: 17px;
  }
  ${({ $isShaking }) =>
    $isShaking &&
    css`
      animation: ${shakeAnimation} ${SHAKEDURATION}ms;
    `}
`;

type Props = {
  lang: string;
  romanization?: boolean;
  style?: React.CSSProperties;
};

const CardSettings: React.FC<Props> = ({ lang, romanization, style }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openHelp, setOpenHelp] = React.useState(false);
  const romanOff = useSelector(selectRomanOff);
  const pairingOff = useSelector(selectPairingOff);
  const dispatch = useDispatch();
  const [isShaking, setIsShaking] = React.useState(false);

  const voiceOffUntil = useGetState("voiceOffUntil");
  const prevVoiceOffUntil = usePrevious(voiceOffUntil);
  const isFirstRender = React.useRef(true);

  React.useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    if (!prevVoiceOffUntil && voiceOffUntil) {
      setIsShaking(true);
      const timeout = setTimeout(() => setIsShaking(false), SHAKEDURATION);
      return () => clearTimeout(timeout);
    }
  }, [voiceOffUntil, prevVoiceOffUntil]);

  return (
    <>
      <SBottomIcon style={style} onClick={(e) => setAnchorEl(e.currentTarget as any)} $isShaking={isShaking}>
        <FontAwesomeIcon icon={faGear} />
      </SBottomIcon>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
        <MenuItem
          onClick={() => {
            setOpenHelp(true);
            setAnchorEl(null);
          }}
        >
          <HelpOutlineIcon /> &nbsp;<Trans>Help</Trans>
        </MenuItem>

        {canTts(lang) && <VoicePickerItem onDone={() => setTimeout(() => setAnchorEl(null), 300)} />}
        {canTts(lang) && <VoiceItem onClick={() => setTimeout(() => setAnchorEl(null), 300)} />}

        <MenuItem
          onClick={() => {
            dispatch(setPairingOff(!pairingOff));
            setAnchorEl(null);
          }}
        >
          <DragIndicatorIcon />
          <Trans>Pairing mode</Trans>{" "}
          <Switch style={{ marginLeft: "auto" }} size="small" checked={!pairingOff} color="primary" />
        </MenuItem>

        {lang === "ja" ? (
          <JapaneseAlphabets onClose={() => setAnchorEl(null)} />
        ) : (
          romanization && (
            <MenuItem
              onClick={() => {
                dispatch(setRomanOff(!romanOff));
                setAnchorEl(null);
              }}
            >
              {romanOff ? <VisibilityOffIcon /> : <VisibilityIcon />} &nbsp;
              <Trans>Romanization</Trans>
              <Switch style={{ marginLeft: "auto" }} size="small" checked={!romanOff} color="primary" />
            </MenuItem>
          )
        )}
      </Menu>

      <SDialogWindow open={openHelp} onClose={() => setOpenHelp(false)}>
        <LearnHints />
      </SDialogWindow>
    </>
  );
};

export default CardSettings;
