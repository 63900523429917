import React, { useEffect } from "react";
import styled from "styled-components/macro";
import { canShowOAuth, topPadding, bottomPadding } from "../../tools/device";
import OAuth from "../../components/other/OAuth";
import EmailAuth from "./EmailAuth";
import ContinueWithEmailButton from "./ContinueWithEmailButton";
import { useToggle } from "react-use";
import { Box, Typography, Stack, Button } from "@mui/material";
import MammothWithBubble, { MammothVariant } from "../tutorial/onboarding/MammothWithBubble";
import { Trans } from "react-i18next";
import { useNavigate } from "react-router";
import { useViewerQuery } from "../../queries/viewerQuery";
import { MAIN } from "../RootRouter.config";
import RegistrationAgreeText from "./RegistrationAgreeText";

const Wrap = styled(Stack)`
  display: flex;
  height: 100dvh;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  justify-content: space-between;
  flex-direction: column;
  background-color: ${({ theme }) => theme.duo.palette.blueVeryLight};
  padding: ${topPadding + 30}px 0 ${bottomPadding + 22}px;
  gap: 10px;
`;
const InnerWrap = styled(Box)`
  padding: 0 30px;
`;
const MammothWrap = styled.div`
  margin-left: auto;
  margin-right: auto;
`;

type Props = {};

export const LateRegistrationPage: React.FC<Props> = () => {
  const [isEmailOpen, toggleEmailOpen] = useToggle(false);
  const navigate = useNavigate();
  const { isGuest, isLoading } = useViewerQuery();

  const notNow = () => {
    navigate(MAIN.url());
  };

  useEffect(() => {
    if (!isGuest) {
      navigate(MAIN.url(), { replace: true });
    }
  }, [isGuest, navigate]);

  if (isLoading || !isGuest) {
    return null;
  }

  return (
    <Wrap>
      <InnerWrap>
        <Typography variant="h6" sx={{ textAlign: "center" }}>
          <Trans>Create your account</Trans>
        </Typography>
      </InnerWrap>
      <MammothWrap>
        <MammothWithBubble variant={MammothVariant.Large}>
          <Trans>Sign up so you don't lose your progress and can log in from any device.</Trans>
        </MammothWithBubble>
      </MammothWrap>
      <InnerWrap>
        {canShowOAuth() && <OAuth />}
        {isEmailOpen ? (
          <Box sx={{ paddingBottom: 2 }}>
            <EmailAuth registrationOnly={true} />
          </Box>
        ) : (
          <ContinueWithEmailButton onClick={toggleEmailOpen} />
        )}

        {!isEmailOpen && (
          <Box sx={{ marginTop: 2 }}>
            <RegistrationAgreeText />
          </Box>
        )}

        <Box sx={{ marginTop: 2 }}>
          <Button variant="text" color="inherit" fullWidth onClick={notNow}>
            <Trans>Not now</Trans>
          </Button>
        </Box>
      </InnerWrap>
    </Wrap>
  );
};
