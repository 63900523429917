import { Button } from "@mui/material";
import styled from "styled-components";

const SButton = styled(Button)`
  grid-area: button;
  height: 60px;
  margin: 0 30px 22px;
  width: calc(100% - 60px);
  z-index: 1;
  &:disabled {
    background: linear-gradient(90deg, #4396d7 0%, #4ba7e8 100%);
    opacity: 0.5;
  }
`;

type Props = {
  onClick?: () => void;
  disabled?: boolean;
  children: React.ReactNode;
};

const OnboardingButton: React.FC<Props> = (props) => {
  return <SButton {...props} variant="contained" disableElevation size="large" />;
};

export default OnboardingButton;
