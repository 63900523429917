import { useSourceCardQuery } from "queries/sources/sourceCardQuery";
import { useViewerDecks } from "queries/viewerQuery";
import React from "react";
import SourceCard from "root/library/library/SourceCard";
import styled from "styled-components";
import Loader from "components/other/Loader";
import useOnboardingData from "hooks/useOnboardingData";
import OnboardingRedirect from "./OnboardingRedirect";
import { StepScreenId } from "./onboardingStepsData";
import { useViewerQuery } from "queries/viewerQuery";
import FirstSubscription, { FIRSTSUBSCRIPTION } from "../misc/FirstSubscription";
import OnboardingButton from "./OnboardingButton";
import { useNavigate } from "react-router";
import { SOURCE } from "root/library/library/libraryUrls";
import { Trans } from "react-i18next";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  zoom: 1.4;
`;

type Props = {
  type: "video" | "article" | "setOrCourse";
  paywall?: boolean;
};

const PreparedSource: React.FC<Props> = ({ type, paywall }) => {
  const { getFlag } = useViewerQuery();
  const { deck } = useViewerDecks();
  const { getOnboardingData } = useOnboardingData();
  const { setOrCourseId, videoId, articleId } = getOnboardingData().preparedSources;
  const sourceId = type === "video" ? videoId : type === "article" ? articleId : setOrCourseId;
  const source = useSourceCardQuery(sourceId || "", deck?.back ?? undefined);
  const { hintWasSeen } = useViewerQuery();
  const navigate = useNavigate();

  const ABTest = getFlag("abtest_sourcesWithContinue")?.value === "on";

  if (paywall && !hintWasSeen(FIRSTSUBSCRIPTION)) {
    return <FirstSubscription />;
  }

  // sources not prepared
  if (sourceId === undefined) return <OnboardingRedirect screenId={StepScreenId.COURSE_PREPARATION} />;

  // source not found
  if (!sourceId) return <OnboardingRedirect screenId={StepScreenId.START_LEARNING} />;

  return (
    <>
      <Wrapper style={{ zoom: ABTest ? 1.2 : 1.4 }}>{source ? <SourceCard source={source} /> : <Loader />}</Wrapper>
      {ABTest && source && (
        <OnboardingButton onClick={() => navigate(SOURCE.url(source.uri))}>
          <Trans>Continue</Trans>
        </OnboardingButton>
      )}
    </>
  );
};

export default PreparedSource;
