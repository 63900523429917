import React from "react";
import styled from "styled-components/macro";
import Card, { CARD_TRANS, Gesture } from "./Card";
import { useCardKnow } from "../../../queries/cards/cardKnow";
import { deleteCardQL } from "../../../queries/cards/deleteCard";
import { loopQuery_loop$data } from "../../../queries/decks/__generated__/loopQuery_loop.graphql";
import { useViewerQuery, useViewerDecks } from "../../../queries/viewerQuery";
import { useIncAdStack } from "../../../components/ads/adStack";
import { useBurn, XpWarnings } from "../../../components/fire/xpHooks";
import { devicePaddings } from "../../../tools/device";
import CardsBottom from "./CardsBottom";
import LearnArrows from "./LearnArrows";
import { useMutation } from "relay-hooks";
import { deleteCardMutation } from "../../../queries/cards/__generated__/deleteCardMutation.graphql";
import Pairs from "./Pairs";
import { useSelector } from "react-redux";
import { selectPairingOff, useSetState } from "../../../components/ReduxProvider";
import HintTooltip, { VOICEHINT } from "components/other/HintTooltip";
import { useSeeHint } from "components/hint/Hint";
import { TUTORIALDONE } from "root/tutorial/steps/Verdict";
import { useGetState } from "components/ReduxProvider";
import { dispatchEvent, EVENT } from "../../../tools/events";
import AIStoryCard from "./AIStoryCard";

export const TOOMANYHINT = "tooMany";
export const CARDSID = "CardsId";

const { bottom, top } = devicePaddings();

const Wrap2 = styled.div`
  position: relative; // keep dots on place
  width: 100%;
  height: 100%;
  padding: ${top + 60}px 0 30px;
`;
const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  height: 100%;
  width: 100%;
  max-width: 650px;
  margin: 0 auto;
  transition: opacity 2s;
  padding-bottom: ${10 + bottom}px;
`;
const CardWrap = styled.div<{ withShadow: boolean }>`
  position: relative;
  height: calc(100% - 70px);
  margin: 0px 35px 15px;
  box-shadow: 0 0 25px ${({ withShadow }) => (withShadow ? `#e2e3e5` : `transparent`)};
  transition: box-shadow 0.4s;
  border-radius: 20px;
`;

type Props = {
  cards: loopQuery_loop$data["loop"];
  onLastCardOutUNRELYABLE?: VoidFunction;
};
const Cards: React.FC<Props> = (p) => {
  const [transitioning, setTransitioning] = React.useState(false);
  const { cardKnow, loading } = useCardKnow();
  const [deleteCard] = useMutation<deleteCardMutation>(deleteCardQL);
  const [cards, setCards] = React.useState(p.cards);
  const [canShowSecondHint, setCanShowSecondHint] = React.useState(false);
  const { hintWasSeen } = useViewerQuery();
  const { deck } = useViewerDecks();
  const [putBelow, setPutBelow] = React.useState(false);
  const [swipeCounter, setSwipeCounter] = React.useState(0);
  const [notLeftYet, setNotLeftYet] = React.useState(true);
  const [turned, setTurned] = React.useState(false);
  const [unBlurred, setUnBlurred] = React.useState(false);
  const incAdStack = useIncAdStack();
  const burn = useBurn();
  const [lastGesture, setLastGesture] = React.useState<Gesture>();
  const [pairsPrepared, setPairsPrepared] = React.useState(false);
  const pairingOff = useSelector(selectPairingOff);
  const [pairingHoldFor, setPairingHoldFor] = React.useState(2);
  const seeHint = useSeeHint();
  const [prevWarn, setPrevWarn] = React.useState<XpWarnings>();
  const loopFilter = useGetState("loopFilter");
  const rightSwipesCount = useGetState("rightSwipesCount");
  const setRightSwipesCount = useSetState("rightSwipesCount");

  const activeId = cards[lastGesture ? 1 : 0]?.id;
  const activeCard = cards.find((c) => c?.id === activeId);

  React.useEffect(() => {
    if (p.cards !== cards && !transitioning) {
      setCards(p.cards);
      setLastGesture(undefined);
      setPutBelow(false);
    }
  }, [p.cards, cards, setCards, transitioning, setLastGesture, setPutBelow]);

  const smoothly = () => {
    setTransitioning(true);
    setTimeout(() => setTransitioning(false), CARD_TRANS * 1.65);
  };

  const isLastCard = p.cards.length === 1;
  const noResponse = p.cards.length > 5 || isLastCard; // we don't need to update cards if we still have 5 or more of them on FE, or when we swipe the last card to prevent flickering

  const know = () => {
    cardKnow({ id: activeId, know: true, noResponse });
    const inc = activeCard?.flipped ? 1 : 0.4;
    setSwipeCounter((c) => (c < 0 ? 0 : c + inc));
    setRightSwipesCount((rightSwipesCount || 0) + 1);
    cards.length === 1 && p.onLastCardOutUNRELYABLE && p.onLastCardOutUNRELYABLE();
  };
  const unknow = () => {
    cardKnow({ id: activeId, know: false, noResponse, isLast: isLastCard });
    setTimeout(() => setPutBelow(true), CARD_TRANS * 0.65);
    setSwipeCounter((c) => (c > 0 ? 0 : c - 1));
    setNotLeftYet(false);
  };
  const del = () => {
    const updater = (store) => {
      const deckRec = store.get(deck?.id || "");
      if (deckRec) {
        const newLoop = deckRec
          .getLinkedRecords("loop", { loopFilter })
          ?.filter((record) => record.getDataID() !== activeId);
        deckRec.setLinkedRecords(newLoop, "loop", { loopFilter });

        if (Number(deck?.stats.unknown) > 0) {
          const stats = deckRec.getLinkedRecord("stats");
          stats?.setValue(Number(deck?.stats.unknown) - 1, "unknown");
        }
      }
    };

    deleteCard({
      variables: { id: activeId, loopFilter },
      optimisticUpdater: updater,
      updater
    });
    dispatchEvent(EVENT.animateBackButton);
    setSwipeCounter((c) => (c > 0 ? c - 2 : c + 2));
    cards.length === 1 && p.onLastCardOutUNRELYABLE && p.onLastCardOutUNRELYABLE();
  };

  const handleGesture = (gesture: Gesture) => {
    const tooManyLeft =
      gesture === "unknow" &&
      (swipeCounter < -(cards.length + 1) ||
        (!hintWasSeen(TOOMANYHINT) && deck && deck.stats.known < 2 && swipeCounter < -2));
    const tooManyRight = gesture === "know" && !hintWasSeen(TOOMANYHINT) && notLeftYet && swipeCounter > 3;

    if ((prevWarn === "tooManyLeft" && gesture !== "unknow") || (prevWarn === "tooManyRight" && gesture !== "know")) {
      seeHint(TOOMANYHINT);
    }

    const warn = tooManyLeft ? "tooManyLeft" : tooManyRight ? "tooManyRight" : undefined;
    burn(cards.length <= 1 ? undefined : warn);
    setPrevWarn(warn);
    smoothly();
    incAdStack(5);
    setTurned(false);
    setLastGesture(gesture);
    setUnBlurred(false);
    setPairingHoldFor((n) => n && n - 1);
    !canShowSecondHint && setCanShowSecondHint(true);

    gesture === "know" && know();
    gesture === "unknow" && unknow();
    gesture === "delete" && del();
  };

  const handleTurn = () => {
    setTurned(true);
    burn(prevWarn);
  };

  const handleRecognition = (correct) => {
    !turned && handleTurn();
    if (!turned && correct && activeCard?.flipped) {
      setSwipeCounter(0);
    }
  };

  const reversed = React.useMemo(() => cards.slice(0, 5).reverse(), [cards]);

  if (!deck) return null;

  return (
    <Wrap2>
      {!pairingOff && !pairingHoldFor && (
        <Pairs
          cards={p.cards}
          cardsLoading={loading}
          hideIfBehind={transitioning}
          onIsPrepared={(value) => {
            !value && setPairingHoldFor(9);
            setPairsPrepared(value);
          }}
        />
      )}
      <Wrap id={CARDSID}>
        <CardWrap withShadow={reversed.length > 1}>
          {reversed.map((card, i) => {
            if (!card) return null;
            const cardIndex = reversed.length - i - 1 - (lastGesture ? 1 : 0);
            return (
              <Card
                card={card}
                unBlurred={unBlurred && cardIndex === 0}
                onUnblur={() => setUnBlurred(true)}
                onGesture={handleGesture}
                putBelow={cardIndex === -1 && putBelow}
                key={card.id}
                index={cardIndex}
                isLast={isLastCard}
                known={cardIndex === -1 && lastGesture === "know"}
                lang={deck.front}
                turned={cardIndex === 0 ? turned : cardIndex === -1}
                blankForTurning={cardIndex === 1 && !turned}
                setTurned={handleTurn}
                silence={pairsPrepared && !pairingOff && !turned}
              />
            );
          })}
          <AIStoryCard cardsAboveCount={p.cards.length} />
        </CardWrap>
        {activeCard && (activeCard.flipped || hintWasSeen(VOICEHINT)) && hintWasSeen(TUTORIALDONE) && (
          <HintTooltip
            title="Here is another awesome feature: Use the keyboard or mic to check yourself"
            canOpen={!pairsPrepared}
            name={VOICEHINT}
          >
            <CardsBottom
              highlight={activeCard.flipped && !turned}
              highlightKeyboard={!turned && !unBlurred && activeCard.knownCount > 1 && !activeCard.flipped}
              lang={deck.front}
              nativeLang={deck.back || ""}
              card={activeCard}
              onRecognition={handleRecognition}
              romanization={!!cards[0].pronunciation}
            />
          </HintTooltip>
        )}

        <LearnArrows />
      </Wrap>
    </Wrap2>
  );
};

export default Cards;
