import React from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useSourceQuery } from "../../../queries/sources/sourceQuery";
import Loader from "components/other/Loader";
import { useViewerDecks, useViewerQuery } from "../../../queries/viewerQuery";
import { useGetState, useSetState } from "../../../components/ReduxProvider";
import Head from "../../../components/header/Head";
import { devicePaddings } from "../../../tools/device";
import { imgOGFormat } from "../../../tools/unsplash";
import { getSourceEditUrl, LANGNATIVEPARAM } from "../library/libraryUrls";
import CardNewButton from "../../main/card/CardNewButton";
import CardsMaxDialog, { useReachedMaximum } from "../../../components/dialogs/CardsMaxDialog";
import { useBurnWithSource, PICKED_FEW_CARDS } from "components/fire/xpHooks";
import { useRedirectIfTutorialInKind } from "root/tutorial/tutorialRedirects";
import { useEffectOnce } from "react-use";
import { CARDS_ACTION } from "../sourceEdit/SourceEdit";
import { FIRSTFEWHINT } from "root/tutorial/steps/FirstFewCards";
import { TUTORIALDONE } from "root/tutorial/steps/Verdict";
import SetCardsFilterDialog from "components/picker/SetCardsFilterDialog";
import SetAndArticleWrap from "./SetAndArticleWrap";
import VideoWrap from "./VideoWrap";
import OpenAssistantButton from "../../main/card/OpenAssistantButton";
import { Trans } from "react-i18next";
import { Stack } from "@mui/material";

const { bottom } = devicePaddings();

const Source: React.FC = () => {
  const { uri } = useParams<{ uri: string }>();
  const { hintWasSeen, isSubscriber } = useViewerQuery();
  const { decks, deck } = useViewerDecks();
  const libTopic = useGetState("libTopic");
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const setLibTopic = useSetState("libTopic");
  const libTopicDeck = decks?.find((d) => d.front === libTopic);
  const ln = searchParams.get(LANGNATIVEPARAM);
  const source = useSourceQuery(uri || "", { ...libTopicDeck, ...(ln ? { back: ln } : {}) }, "store-or-network");
  const setDeckId = useSetState("deckId");
  const xp = useGetState("xp");
  const reachedMaximum = useReachedMaximum();

  const { getFlag } = useViewerQuery();
  const isNewPickerAbtest: boolean = getFlag("abtest_newPicker")?.value === "on";
  const maxCardsToLearnDialogAbTest = getFlag("abtest_maxCardsToLearnDialog")?.value === "on";

  const burnW = useBurnWithSource(source?.lang);
  useEffectOnce(() => burnW());

  useRedirectIfTutorialInKind(source?.kind, deck);

  const deckToBeSet =
    deck && source && deck.front !== source.lang && decks && decks.find((d) => d.front === source.lang);

  React.useEffect(() => {
    if (deckToBeSet) {
      setDeckId(deckToBeSet.id);
    }
    if (source?.lang && (!libTopic || source.lang !== libTopic)) {
      setLibTopic(source.lang);
    }
  }, [deckToBeSet, source, libTopic, setDeckId, setLibTopic]);

  useEffectOnce(() => {
    if (!hintWasSeen(FIRSTFEWHINT) && !hintWasSeen(TUTORIALDONE)) navigate({ hash: "noback" }); // block first native-back-button to force user pick 3 cards
  });

  if (!source || !source.lang || deckToBeSet) return <Loader />;

  const showFirstQuestDialog =
    !hintWasSeen(PICKED_FEW_CARDS) && maxCardsToLearnDialogAbTest && xp.warn === "tooManyToLearn";

  return (
    <>
      <Head
        title={source.name + (source.kind === "set" ? ` - ${source.seo}` : "")}
        image={source?.image?.url && imgOGFormat(source?.image?.url)}
        description={source.text || source.sCards.map((c) => c.front).join(", ") || undefined}
      />

      {source.kind === "video" ? <VideoWrap source={source} /> : <SetAndArticleWrap source={source} />}

      {(hintWasSeen(FIRSTFEWHINT) || hintWasSeen(TUTORIALDONE) || !isNewPickerAbtest) && (
        <>
          <OpenAssistantButton style={{ right: "46px", bottom: bottom + 5 }} />
          <CardNewButton
            onClickOverwrite={
              source.mine && source.kind === "set" ? () => navigate(getSourceEditUrl(source, CARDS_ACTION)) : undefined
            }
            style={{ bottom: bottom + 5, right: "5px" }}
          />
        </>
      )}

      <SetCardsFilterDialog source={source} />

      {source.kind === "set" && showFirstQuestDialog && (
        <CardsMaxDialog
          showPermanently
          hideSubscribeButton
          title={<Trans>Quest completed!</Trans>}
          message={
            <Stack spacing={2}>
              <Trans parent={"span"}>
                Great, I see you've already added your cards. <strong>Let's learn them</strong>.
              </Trans>
            </Stack>
          }
        />
      )}

      {(source.kind === "article" || source.kind === "video") && reachedMaximum && !isSubscriber && (
        <CardsMaxDialog
          showPermanently
          subscribeButtonLabel={<Trans>Continue {source.kind === "article" ? "reading" : "watching"}</Trans>}
        />
      )}
    </>
  );
};

export default Source;
