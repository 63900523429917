import React from "react";
import styled, { css, keyframes } from "styled-components/macro";
import { xor } from "lodash";
import Theory from "components/Theory/Theory";
import TheoryParser from "components/Theory/TheoryParser";
import Toggler from "components/other/Toggler";
import { TSource } from "types/SourceTypes";
import { smoothScroll } from "tools/smoothScroll";
import { useEffectOnce } from "react-use";
import FlatImg from "components/other/FlatImg";
import CircularProgress from "@mui/material/CircularProgress";
import { TTheoryBox, useGetTheories } from "root/library/source/course/courseHooks";
import { useTheoryPickedLang } from "components/Theory/theoryTools";
import TheoryToggler from "components/Theory/TheoryToggler";
import { imgCourseFormat } from "tools/unsplash";
import { TitleOverImage } from "root/library/library/styled";

const COMPLETED_IMG_ANIMATION_DURATION = 1000;

const pulsingKF = keyframes`
  0%  { transform: scale(0); }
  50% {  transform: scale(1.2); }
  70% {  transform: scale(0.8); }
  80% {  transform: scale(1.1); }
  90% {  transform: scale(0.9); }
  100% { transform: scale(1); }
`;

const Wrap = styled.div<{ $ready: boolean }>`
  background: ${(p) => p.theme.duo.color.lightGrey};
  padding: 1px 0;
  ${(p) => !p.$ready && `visibility: hidden;`}
`;
const TheoryBox = styled.div<{ $hidden: boolean }>`
  cursor: pointer;
  position: relative;
  margin: 30px 60px;
  padding: 20px;
  min-width: 320px;
  border-radius: ${({ theme }) => theme.duo.borderRadius.normal};
  box-shadow: ${({ theme }) => theme.duo.boxShadow.normal};
  background: white;
  transition: opacity 0.3s;

  @media (max-width: 580px) {
    margin-left: 45px;
    margin-right: 45px;
  }
  @media (max-width: 490px) {
    margin-left: 30px;
    margin-right: 30px;
    padding-left: 15px;
    padding-right: 15px;
  }
  @media (max-width: 430px) {
    border-radius: 0;
    margin-left: 0;
    margin-right: 0;
    padding-left: 10px;
    padding-right: 10px;
    opacity: ${(p) => (p.$hidden ? 0 : 1)};
  }
`;
const TheoryTitle = styled.div<{ $bold: boolean }>`
  margin: 15px;
`;
const TheoryRow = styled.div<{ $colored: boolean }>`
  ${({ $colored }) => !$colored && `filter: grayscale(1);`}
  ${({ $colored }) =>
    $colored &&
    css`
      ${TheoryTitle} {
        font-weight: bold;
      }
    `}
`;
const TheoryImageWrap = styled.div`
  width: 100px;
  height: 100px;
  margin: 10px auto;
  position: relative;
`;
const SArrow = styled.img`
  width: 40px;
  height: 40px;
  display: block;
  margin: 30px auto;
`;
const CheckedImg = styled.img<{ $animation: "none" | "waiting" | "animating" }>`
  width: 50px;
  height: 50px;
  position: absolute;
  right: -19px;
  top: -15px;
  z-index: 4;

  ${(p) => {
    if (p.$animation === "none") {
      return `transform: scale(1);`;
    }
    if (p.$animation === "waiting") {
      return `transform: scale(0);`;
    }
    return css`
      animation: ${pulsingKF} ${COMPLETED_IMG_ANIMATION_DURATION}ms ease-out forwards;
    `;
  }}
`;
const Anchor = styled.div`
  position: relative;
  top: -30px;

  @media (max-width: 430px) {
    top: -100px;
  }
`;
const ImgWrap = styled.div`
  position: relative;
  top: -1px;
`;
const ImgBg = styled.div`
  width: 100%;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  background-size: contain;
`;
const H1Wrap = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const SCircularProgress = styled(CircularProgress)`
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;

  circle {
    color: #32bb7c;
    stroke-width: 2px;
  }
`;
const GreyCircularProgress = styled(CircularProgress)`
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;

  circle {
    color: lightgrey;
    stroke-width: 2px;
  }
`;
const SFlatImg = styled(FlatImg)`
  width: 80px;
  height: 80px;
  position: absolute;
  left: 10px;
  top: 10px;
  z-index: 3;
  border-radius: 100%;
  overflow: hidden;

  img {
    margin: 0 !important;
  }
`;
const TheoryTogglerWrap = styled.div`
  position: absolute;
  left: 10px;
  top: 10px;
  z-index: 10;
`;

type Props = {
  source: TSource;
  allowScrollAnimation?: boolean;
  openTheoryByCardId?: string;
  onTheoryEditToggle?: (to: boolean) => void;
};

const CourseBlocks: React.FC<Props> = ({ source, allowScrollAnimation, openTheoryByCardId, onTheoryEditToggle }) => {
  const theories = useGetTheories(source, openTheoryByCardId);
  const firstTheory = theories[0]?.theory;
  const theoryPickedLang = useTheoryPickedLang(firstTheory);
  const [toggledTheories, setToggledTheories] = React.useState<number[]>([]);
  const activeBoxRef = React.useRef<HTMLDivElement>(null);
  const completedBoxRef = React.useRef<HTMLDivElement>(null);
  const editingTheoryRef = React.useRef<HTMLDivElement>(null);
  const [animationDone, setAnimationDone] = React.useState(false);
  const [animateCheckedImg, setAnimateCheckedImg] = React.useState(false);
  const [checkedImgAnimated, setCheckedImgAnimated] = React.useState(false);
  const [hasTouched, setHasTouched] = React.useState(false); // when user on mobile comes here, we hide all blocks other than the current opened one, until he touches the view again
  const [editingTheoryId, setEditingTheoryId] = React.useState<string>("");
  const [lastEditedTheoryId, setLastEditedTheoryId] = React.useState("");

  const handleTheoryClick = (theoryIndex) => {
    setToggledTheories(xor(toggledTheories, [theoryIndex]));
  };

  const isTheoryOpen = (theoryIndex, theory: TTheoryBox): boolean => {
    return toggledTheories.includes(theoryIndex) ? !theory.isCurrent : theory.isCurrent;
  };

  const handleTheoryEdit = (to: boolean, cardId: string) => {
    if (to) {
      setEditingTheoryId(cardId);
      setLastEditedTheoryId(cardId);
    } else {
      setEditingTheoryId("");
      setTimeout(() => {
        editingTheoryRef.current?.scrollIntoView({ behavior: "auto", block: "start" });
      });
    }

    onTheoryEditToggle && onTheoryEditToggle(to);
  };

  // first animate completed img if it should be animated
  useEffectOnce(() => {
    if (allowScrollAnimation && completedBoxRef.current) {
      smoothScroll(completedBoxRef.current, "auto").then(() => {
        setAnimateCheckedImg(true);
        setTimeout(() => setCheckedImgAnimated(true), COMPLETED_IMG_ANIMATION_DURATION);
      });
    } else {
      setCheckedImgAnimated(true);
    }
  });

  // animate scroll to current theory either right now or after the completed img is animated
  React.useEffect(() => {
    // don't scroll to the first theory so user can see the course title
    if (theories[0]?.isCurrent) {
      setAnimationDone(true);
      return;
    }

    if (checkedImgAnimated && activeBoxRef.current) {
      smoothScroll(activeBoxRef.current, allowScrollAnimation ? "smooth" : "auto").then(() => {
        setAnimationDone(true);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedImgAnimated]);

  const handleTouch = () => {
    setHasTouched(true);
  };

  return (
    <Wrap
      $ready={(allowScrollAnimation && (animateCheckedImg || checkedImgAnimated)) || animationDone}
      onTouchStart={handleTouch}
      onMouseMove={handleTouch}
    >
      {!editingTheoryId && (
        <>
          <TheoryTogglerWrap>
            <TheoryToggler theory={firstTheory} value={theoryPickedLang} courseLayout />
          </TheoryTogglerWrap>

          <ImgWrap>
            <ImgBg style={{ backgroundImage: imgCourseFormat(source.image?.url || "") }} />
            <H1Wrap>
              <TitleOverImage>{source.name}</TitleOverImage>
            </H1Wrap>
          </ImgWrap>
        </>
      )}
      {theories.map((theory, theoryIndex) => {
        if (editingTheoryId && theory.cardId !== editingTheoryId) return null;
        return (
          <TheoryRow
            key={theoryIndex}
            $colored={theory.isCompleted || theory.isCurrent || theory.cardId === editingTheoryId}
          >
            {theoryIndex > 0 && !editingTheoryId && <SArrow src="https://cdn.duocards.com/course/arrow-down.svg?3" />}
            {theory.isCurrent && <Anchor ref={activeBoxRef}></Anchor>}
            {theory.cardId === lastEditedTheoryId && <Anchor ref={editingTheoryRef}></Anchor>}
            {theory.animateCompletion && <Anchor ref={completedBoxRef}></Anchor>}
            <TheoryBox
              onClick={() => {
                handleTheoryClick(theoryIndex);
              }}
              $hidden={!!openTheoryByCardId && !hasTouched && !theory.isCurrent}
            >
              <TheoryImageWrap>
                <SCircularProgress variant="determinate" value={theory.progress} size={100} />
                <GreyCircularProgress variant="determinate" value={100} size={100} />
                {theory.isCompleted && (
                  <CheckedImg
                    $animation={
                      !allowScrollAnimation || !theory.animateCompletion
                        ? "none"
                        : animateCheckedImg || checkedImgAnimated
                          ? "animating"
                          : "waiting"
                    }
                    src={"https://cdn.duocards.com/course/checked.png"}
                  />
                )}
                <SFlatImg img={theory.image} size={80} />
              </TheoryImageWrap>
              <TheoryTitle $bold={theory.isCompleted || theory.isCurrent}>
                <TheoryParser theory={theory.title} lang={source.lang}></TheoryParser>
              </TheoryTitle>

              <Toggler show={animationDone && isTheoryOpen(theoryIndex, theory)}>
                <Theory
                  theory={theory.theory}
                  coursePreview={true}
                  hideEdit={!animationDone || !isTheoryOpen(theoryIndex, theory)}
                  cardId={theory.cardId}
                  onTheoryEditToggle={handleTheoryEdit}
                ></Theory>
              </Toggler>
            </TheoryBox>
          </TheoryRow>
        );
      })}
    </Wrap>
  );
};

export default CourseBlocks;
