import React from "react";
import styled from "styled-components/macro";

const Wrap = styled.div<{}>`
  display: flex;
  justify-content: center;
  pointer-events: none;
`;
const Img = styled.img<{ $isPng: boolean; $size?: number }>`
  width: ${({ $size }) => $size || 100}px;
  height: ${({ $size }) => $size || 100}px;
  /* to compensate usual blank pixels around SVG */
  margin: ${({ $isPng }) => ($isPng ? -5 : 0)}px 0;
`;

type Props = {
  img?: { url: string } | null;
  className?: string;
  size?: number;
};

const FlatImg: React.FC<Props> = ({ img, className, size }) => {
  if (!img) return null;

  return (
    <Wrap className={className}>
      <Img $isPng={img.url.endsWith(".png")} src={img.url} $size={size} />
    </Wrap>
  );
};

export default FlatImg;
