import React from "react";
import styled from "styled-components/macro";
import { Box, IconButton, Paper, Stack } from "@mui/material";
import { useViewerDecks, useViewerQuery } from "queries/viewerQuery";
import MammothIcon from "icons/MammothIcon";
import Contextor from "root/library/source/contextor/Contextor";
import { ChatMessageOwnerType } from "./__generated__/AssistantMutation.graphql";
import TypingDots from "./TypingDots";
import { graphql } from "babel-plugin-relay/macro";
import { useMutation } from "relay-hooks";
import { ChatBubbleMutation } from "./__generated__/ChatBubbleMutation.graphql";
import { TChatBubble } from "./Assistant";
import { htmlParser } from "../../tools/htmlParser";
import { faTranslate } from "@awesome.me/kit-55349eb283/icons/kit/custom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TTSButton from "./TTSButton";

export type ChatBubbleOwner = ChatMessageOwnerType;

const BubbleWrap = styled(Stack)<{ $owner: ChatBubbleOwner }>`
  justify-content: ${(p) => (p.$owner === "user" ? "flex-end" : "flex-start")};
  flex-direction: row;
  align-items: flex-start;
  font-size: 16px;
`;
const Bubble = styled(Paper)<{ $owner: ChatBubbleOwner; $transparentBubble?: boolean }>`
  ${(p) =>
    p.$transparentBubble ? "background-color: transparent" : p.$owner === "user" ? `background-color: #c1e4ff` : ""};
  line-height: 1.6;
  padding: 10px;
  margin-left: ${(p) => (p.$owner === "user" ? "0" : "9px")};
  text-align: left;
  position: relative;
  white-space: pre-wrap;
  ${(p) => (p.$transparentBubble ? `border: 1px solid ${p.theme.duo.color.grey};` : "border: none;")}

  b {
    font-weight: normal;
    color: ${(p) => p.theme.duo.color.textAccent};
  }
`;
const SMammothIcon = styled(MammothIcon)`
  width: 50px;
  height: 50px;
  display: inline-block;
  flex-shrink: 0;

  @media (max-width: 440px) {
    width: 32px;
    height: 32px;
  }
`;
const SIconButton = styled(IconButton)`
  color: ${(p) => p.theme.duo.color.lightBlue};
  height: 40px;
  width: 40px;
  svg {
    width: 20px;
    height: 20px;
  }
`;
const TranslationWrap = styled.div`
  background: ${(p) => p.theme.duo.palette.blueVeryLight2};
  padding: 10px;
  margin: 10px -10px -10px -10px;
  border-radius: 0 0 4px 4px;
  position: relative;
  &::before {
    content: "";
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid ${(p) => p.theme.duo.palette.blueVeryLight2};
    position: absolute;
    right: 17px;
    top: -6px;
  }
`;
const ChatBubbleActions = styled(Box)`
  float: right;
  display: flex;
  gap: 15px;
  flex-direction: row;
  height: 30px;
  margin-top: -10px;
  margin-right: -10px;
`;

const TTSButtonWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
`;

const translateChatMessage = graphql`
  mutation ChatBubbleMutation($messageId: String, $text: String!, $fromLang: String!, $toLang: String!) {
    translateChatMessage(messageId: $messageId, toLang: $toLang, fromLang: $fromLang, text: $text)
  }
`;

type Props = {
  chatMessage: TChatBubble;
  isTyping?: boolean;
  onBurn?: VoidFunction;
  className?: string;
  transparentBubble?: boolean;
  additionalInfo?: React.ReactNode;
  playOnMount?: boolean;
};

const ChatBubble = React.forwardRef<HTMLDivElement, Props>(
  ({ chatMessage, isTyping, onBurn, className, transparentBubble, additionalInfo, playOnMount }, forwardRef) => {
    const { deck } = useViewerDecks();
    const { viewer } = useViewerQuery();
    const [isTranslateOpen, setIsTranslateOpen] = React.useState(false);
    const [doTranslateChatMessage] = useMutation<ChatBubbleMutation>(translateChatMessage, {
      onCompleted: (data) => {
        translationRef.current = data.translateChatMessage;
      }
    });
    const translationRef = React.useRef<string | null>(null);
    translationRef.current ??= chatMessage.translation || null;
    const htmlStrippedText = chatMessage.text.replace(/<[^>]*>/g, "");

    React.useEffect(() => {
      translationRef.current = null;
      setIsTranslateOpen(false);
    }, [chatMessage.text]);

    const handleTranslateClick = () => {
      setIsTranslateOpen(true);
      if (!translationRef.current) {
        doTranslateChatMessage({
          variables: {
            text: htmlStrippedText,
            fromLang: deck!.front,
            toLang: deck!.back!,
            messageId: chatMessage.id
          }
        });
        onBurn?.();
      }
    };

    if (!viewer) return null;

    return (
      <BubbleWrap $owner={chatMessage.owner} className={className} ref={forwardRef}>
        {chatMessage.owner === "assistant" && <SMammothIcon className="nofilter" />}
        <Box>
          <Bubble elevation={0} $owner={chatMessage.owner} $transparentBubble={transparentBubble}>
            {chatMessage.owner === "assistant" ? (
              <Contextor>
                {htmlParser(chatMessage.text)}
                {!isTyping && (
                  <>
                    <ChatBubbleActions>
                      <TTSButtonWrap>
                        <TTSButton text={htmlStrippedText} playOnMount={playOnMount} />
                      </TTSButtonWrap>
                      <SIconButton onClick={handleTranslateClick}>
                        <FontAwesomeIcon icon={faTranslate} />
                      </SIconButton>
                    </ChatBubbleActions>
                    <Box sx={{ clear: "both" }} />
                  </>
                )}
              </Contextor>
            ) : (
              chatMessage.text
            )}

            {isTyping && <TypingDots />}
            {isTranslateOpen && (
              <TranslationWrap>
                {!translationRef.current && <TypingDots />}
                {translationRef.current}
              </TranslationWrap>
            )}
          </Bubble>
          {additionalInfo}
        </Box>
      </BubbleWrap>
    );
  }
);

export default ChatBubble;
