import React from "react";
import styled, { css } from "styled-components/macro";
import Flames from "../../components/fire/Flames";
import seedrandom from "seedrandom";

function stringToColour(str) {
  const rng = seedrandom(str ? str.charCodeAt(0) : "x");
  const rand359 = Math.floor(rng() * 360);
  return `hsl(${rand359}, 50%, 50%)`;
}

const Wrap = styled.div`
  margin: 0 auto;
  display: inline-block;
  position: relative;
`;
const Avt = styled.div<{ $responsive?: boolean }>`
  width: 50px;
  line-height: 50px;
  display: inline-block;
  overflow: hidden;
  font-size: 23px;
  text-align: center;
  color: white;
  background: grey;
  border-radius: 50%;

  ${(p) =>
    p.$responsive &&
    css`
      @media (max-width: 440px) {
        width: 32px;
      }
    `}
`;
const SImg = styled.img<{ $responsive?: boolean }>`
  width: 50px;
  line-height: 50px;
  display: inline-block;
  overflow: hidden;
  border-radius: 50%;

  ${(p) =>
    p.$responsive &&
    css`
      @media (max-width: 440px) {
        width: 32px;
      }
    `}
`;
const SFlames = styled(Flames)`
  position: absolute;
  right: -10px;
  bottom: -7px;
`;

type Props = {
  user: {
    name: string | null;
    picture: string | null;
    xps?: {
      total?: number;
    };
  } | null;
  noXp?: boolean;
  className?: string;
  responsive?: boolean;
};

const Avatar: React.FC<Props> = ({ user, noXp, className, responsive }) => {
  const letter = (user?.name || "?")[0].toUpperCase();

  return (
    <Wrap className={className}>
      {user?.picture ? (
        <SImg
          src={user.picture}
          $responsive={responsive}
          referrerPolicy="no-referrer" // https://stackoverflow.com/questions/40570117/http403-forbidden-error-when-trying-to-load-img-src-with-google-profile-pic
        />
      ) : (
        <Avt style={{ background: letter === "?" ? "#ddd" : stringToColour(letter) }} $responsive={responsive}>
          {letter}
        </Avt>
      )}
      {!noXp && !!user?.xps?.total && (
        <SFlames
          strength={user?.xps?.total ? (user.xps.total > 400 ? 4 : Math.ceil(user.xps.total / 100)) : 0}
          xps={user?.xps?.total || null}
          noZZ
        />
      )}
    </Wrap>
  );
};

export default Avatar;
