import { useSetState } from "components/ReduxProvider";
import React from "react";
import { useViewerQuery } from "../../queries/viewerQuery";
import { useNavigate } from "react-router";
import { NOTIFICATION } from "../../sharedJs__generated/constants";
import { NOCARDSHASH } from "../dialogs/NoCardsDialog";
import { useEffectOnce, useEvent } from "react-use";
import { EVENT } from "tools/events";
import { useDBLog } from "hooks/useDBLog";
import { NOTIFICATIONDBLOG } from "sharedJs__generated/constants";
import { SUBSCRIPTION } from "../../root/profile/ProfileRouter.config";
import { MAIN } from "../../root/RootRouter.config";
import useOnboardingData from "hooks/useOnboardingData";

const messaging = (window as any).cordova?.plugins?.firebase?.messaging;

const NotificationsRegister: React.FC<{}> = () => {
  const { viewer, getFlag } = useViewerQuery();
  const setFcmId = useSetState("fcmId");
  const navigate = useNavigate();
  const dbLog = useDBLog();
  const { getOnboardingData } = useOnboardingData();
  const newOnboardingFlagOn = getFlag("abtest_onboarding")?.value === "on";

  const handleRegistration = () => {
    if (
      messaging &&
      viewer &&
      viewer.coachLevel &&
      (!newOnboardingFlagOn || getOnboardingData().latestStepReached !== undefined)
    ) {
      messaging
        .requestPermission()
        .then(() => {
          messaging.getToken().then((fcmId) => {
            setFcmId(fcmId);
          });
          messaging.onBackgroundMessage((payload) => {
            // messaging.setBadge(1); // we might play (abtest) with icon badging in future
            if ([NOTIFICATION.sale, NOTIFICATION.blackFridayNotif, NOTIFICATION.expired].includes(payload.notifName)) {
              navigate(SUBSCRIPTION.url());
            }
            if ([NOTIFICATION.noCards].includes(payload.notifName)) {
              navigate(MAIN.url() + NOCARDSHASH, { replace: true });
            }
            dbLog(NOTIFICATIONDBLOG, "notification opened", { notifName: payload.notifName });
          });
        })
        .catch((d) => {
          setFcmId(null);
        });
      // messaging.onMessage(... here we can handle notifications when app is open...);
    }
  };

  useEffectOnce(() => {
    handleRegistration();
  });

  useEvent(EVENT.refreshNotifications, () => {
    handleRegistration();
  });

  return null;
};

export default NotificationsRegister;
